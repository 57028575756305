import React, {useState} from 'react';
import axios from "axios";
import {GiPlainCircle} from "react-icons/gi";
import thumb from "../../assets/images/service-details-thumb.jpg";
import Quill from "../../lib/Quill";
import {getTableSortLabelUtilityClass} from "@mui/material";
import axiosInstance from "../Helper/AxiosHelper";

const Firma = ({company}) => {
    const [isShown, setIsShown] = useState(false);
    const [companyName, setCompanyName] = useState(company.name);
    const [companyWebsite, setCompanyWebsite] = useState(company.website);
    const [companyDescription, setCompanyDescription] = useState(company.description);
    const [isPublic, setIsPublic] = useState(company.isPublic);
    const [image, setImage] = useState(`data:${company.type};base64,${company.data}`);
    const [imageToSend, setImageToSend] = useState();
    const [isNameEditable, setIsNameEditable] = useState(false);
    const [isWebsiteEditable, setIsWebsiteEditable] = useState(false);

    const showEditor = (e) => {
        if (localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro') {
            if (isShown) {
                updateText(e).then(r => console.log(r));
            }
            setIsShown(!isShown);
        }

    };
    // function selectCompanyStatus() {
    //     setIsPublic(!isPublic);
    // }

    const selectCompanyStatus = (e) => {
        setIsPublic(!isPublic);
    }

    const updateText = async(e) => {
        e.preventDefault();
        setIsNameEditable(false);
        setIsWebsiteEditable(false);
        // const stringHtml = document.getElementById('tryEdit').innerHTML.toString();
        // const companyJson = JSON.stringify({
        //     name : companyName,
        //     website: companyWebsite,
        //     description : stringHtml,
        //     isPublic : isPublic,
        // })
        const companyJson = getCompany();
        try {
            // const response = await axios({
            //     method: "PATCH",
            //     url: "https://neets-mnt-backend.azurewebsites.net/api/sv/company/update",
            //     // url: "http://localhost:8080/api/company/update",
            //     data: companyJson,
            //     headers: { "Content-Type": "application/json" },
            //     params: {
            //         id: company.id
            //     },
            // });
            const response = await axiosInstance.patch("/api/sv/company/update", companyJson, {
                headers: { "Content-Type": "application/json" },
                params: {
                    id: company.id
                },
            });
            const data1 = await response;
        } catch(error) {
            console.log(error)
        }
    }

    function getCompany() {
        const stringHtml = document.getElementById('tryEdit').innerHTML.toString();
        console.log(isPublic);
        return JSON.stringify({
            name : companyName,
            website: companyWebsite,
            description : stringHtml,
            isPublic : isPublic,
        })
    }

    function handleNameChange(e) {
        setCompanyName(e.target.value)
    }
    function handleWebsiteChange(e) {
        setCompanyWebsite(e.target.value)
    }


    const handleSubmit = async(event) => {
        // setIsShown(true);
        // console.log(selectedFile);
        event.preventDefault()
        const formData = new FormData();
        // selectedFile.forEach(file => {
        formData.append("image", imageToSend);
        formData.append("id", company.id);
        // })
        try {
            // const response = await axios({
            //     method: "patch",
            //     url: "https://neets-mnt-backend.azurewebsites.net/api/sv/company/update/image",
            //     // url: "http://localhost:8080/api/company/update/image",
            //     data: formData,
            //     headers: {"Content-Type": "multipart/form-data"},
            //     responseType: "blob",
            // });
            // setImage(`data:${response.data.type};base64,${response.data.data}`);
            await axiosInstance.patch("/api/sv/company/update/image", formData, {
                headers: {"Content-Type": "multipart/form-data"},
                responseType: "blob",
            });
        } catch (error) {
            console.log(error)
        }
    }

    function uploadImage(event) {
        setImage(URL.createObjectURL(event.target.files[0]));
        setImageToSend(event.target.files[0]);
    }

    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                    <div className="service-category-widget" style={{textAlign: 'center'}}>
                                        <form onSubmit={updateText}>
                                            <label htmlFor="filePicker3">
                                                <div onClick={selectCompanyStatus} style={{textAlign: 'center', cursor:"pointer"}}  >
                                                    {isPublic
                                                        ? <h3 style={{ color: 'red'}}><GiPlainCircle /> Ascunde Firma</h3>
                                                        : <h3 style={{ color: 'green'}}><GiPlainCircle /> Publica Firma</h3>
                                                    }
                                                </div>
                                                <input id="filePicker3" type="submit" name="file3" style={{display: "none"}}></input>
                                            </label>
                                        </form>
                                    </div>
                                )}

                                <div className="service-category-widget">
                                    {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                        <form onSubmit={updateText}>
                                            <ul>
                                                {isNameEditable && (
                                                    <li>
                                                        <label htmlFor="filePicker1">
                                                            <i className="fal fa-building"></i>
                                                            <input id="filePicker1" type="text" placeholder={companyName} name="file1" style={{maxWidth: '190px'}} onChange={handleNameChange}>
                                                            </input>
                                                            <button type="submit" >
                                                                <i className="fal fa-upload" />
                                                            </button>
                                                        </label>
                                                    </li>
                                                )}
                                                {!isNameEditable && (
                                                    <li onClick={() => setIsNameEditable(!isNameEditable)}>
                                                        <i className="fal fa-building"></i>{companyName}
                                                    </li>
                                                )}
                                                {isWebsiteEditable && (
                                                    <li>
                                                        <label htmlFor="filePicker2">
                                                            <i className="fal fa-link"></i>
                                                            <input id="filePicker2" type="text" placeholder={companyWebsite} name="file2" style={{maxWidth: '190px'}} onChange={handleWebsiteChange}>
                                                            </input>
                                                            <button type="submit" >
                                                                <i className="fal fa-upload"></i>
                                                            </button>
                                                        </label>
                                                    </li>
                                                )}
                                                {!isWebsiteEditable && (
                                                    <li onClick={() => setIsWebsiteEditable(!isWebsiteEditable)}>
                                                        <i className="fal fa-link"></i><a href={companyWebsite} target="_blank">Website-ul firmei</a>
                                                    </li>
                                                )}

                                            </ul>
                                        </form>
                                    )}
                                    {localStorage.getItem('user') !== 'cristina.udateanu@goodstory.ro' && (
                                        <ul>
                                            <li>
                                                <i className="fal fa-building"></i>{company.name}
                                            </li>
                                            <li>
                                                <i className="fal fa-link"></i><a href={company.website} target="_blank">Website-ul firmei</a>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                                {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                    <div className="service-category-widget">
                                        <form onSubmit={handleSubmit}>
                                            <ul>
                                                <li>
                                                    <label htmlFor="imagePicker"  style={{cursor:"pointer"}}>
                                                        <i className="fal fa-upload"></i>Incarca imagine
                                                        <input id="imagePicker" type="file" name="file" onChange={uploadImage} style={{display: "none"}}></input>
                                                        <button type="submit" >
                                                            Save
                                                        </button>
                                                    </label>
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="thumb">
                                    {image !== 'data:null;base64,null' && (
                                        <img src={image} alt="" />
                                    )}
                                    {image === 'data:null;base64,null' && (
                                        <img src={thumb} alt="" />
                                    )}
                                </div>
                                {isShown && (
                                    <div>
                                        <Quill
                                            actualText={{
                                                container: document.getElementById('tryEdit'),
                                            }}
                                        />
                                        <button type="submit" onClick={showEditor}>
                                            Save
                                        </button>
                                    </div>
                                )}
                                <div id="tryEdit" className="content" onClick={showEditor}>
                                    <div dangerouslySetInnerHTML={{ __html: company.description}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Firma;
