import React from 'react';

const Intro = () => {
    return (
        <>
            <section
                className="appie-services-2-area appie-services-8-area pt-90 pb-55"
                id="service"
            >
                <div className="container" >
                    <div className="row align-items-end">
                        <div className="col-lg-12 col-md-8" >
                            <div className="appie-section-title text-center" >
                                <h3 className="appie-title">Concurs Planuri De Afaceri</h3>
                                <p style={{fontSize: '24px'}}><b>Poti primi 120.000 lei fonduri nerambursabile pentru afacerea ta!</b></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div
                                    className="appie-download-3-box wow animated fadeInLeft"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <div className="content">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12">
                                                <div className="appie-how-it-work-content">
                                                    <div className="row" >
                                                        <div className="col-sm-12">
                                                            <div className="how-it-work-box" >
                                                                <span><i className="fal fa-info-circle" /></span>
                                                                <p align="justify">
                                                                    In urma derularii concursului de planuri de afaceri, cei 15 castigatori ai concursului de planuri de
                                                                    afaceri, apartinand categoriei A de NEETS, vor primi in urma semnarii contractului de subventie, o
                                                                    finantare nerambursabila de 120.000 lei.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="how-it-work-box" >
                                                                <span><i className="fal fa-info-circle" /></span>
                                                                <p align="justify">
                                                                    Pentru subventia acordata este obligatorie angajarea a minimum 1 persoana in cadrul afaceri
                                                                    sprijinite.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="how-it-work-box" >
                                                                <span><i className="fal fa-info-circle" /></span>
                                                                <p align="justify">
                                                                    Afacerile infiintate trebuie sa functioneze minimum 12 de luni pe perioada implementarii
                                                                    proiectului si sa asigure o perioada de sustenabilitate de minimum 6 luni.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Intro;
