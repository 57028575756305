import React, {useEffect} from 'react';
import StickyMenu from "../../lib/StickyMenu";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import Intro from "./Intro";
import FormareAntreprenoriala from "./FormareAntreprenoriala";
import {Divider} from "@mui/material";
import Etapa1 from "./Etapa1";
import { Element } from 'react-scroll';

const PaginaConcursPlanDeAfaceri = () => {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <Divider variant={"middle"}/>
            <Element id='concurs1' name='concurs1'>
                <Intro />
            </Element>
            <Divider variant={"middle"}/>
            <Element id='formare-antreprenoriala' name='formare-antreprenoriala'>
                <FormareAntreprenoriala />
            </Element>
            <Divider variant={"middle"}/>
            <Element id='calendar-concurs' name='calendar-concurs'>
                <Etapa1 />
            </Element>
            {/*<Divider variant={"middle"}/>*/}
            {/*<Etapa3 />*/}
            {/*<Divider variant={"middle"}/>*/}
            {/*<Etapa4 />*/}
            {/*<Divider variant={"middle"}/>*/}
            {/*<Etapa5 />*/}
            {/*<Divider variant={"middle"}/>*/}
            {/*<Etapa6 />*/}
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaConcursPlanDeAfaceri;
