import React, {useState} from 'react';
import blogImg1 from "../../assets/images/blog/1.jpg";
import {FaPlusCircle} from "react-icons/fa";
// import blogImg1 from "../../assets/images/firmepic1.jpg";
import axios from "axios";
import {Link} from "react-router-dom";
import axiosInstance from "../Helper/AxiosHelper";

const Firme = ({companies, setCompanies}) => {
    const addNewCompany = async(e) => {
        e.preventDefault();
        const company = JSON.stringify({
            name: "Nume Firma",
            website: "www.website.com",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            isPublic: false
        })
        try {
            // const response = await axios({
            //     method: "POST",
            //     url: "https://neets-mnt-backend.azurewebsites.net/api/sv/company/add",
            //     // url: "http://localhost:8080/api/company/add",
            //     data: company,
            //     headers: { "Content-Type": "application/json" },
            // });
            const response = await axiosInstance.post("/api/sv/company/add", company, {
                headers: { "Content-Type": "application/json" },
            });
            const data1 = await response;
        } catch(error) {
            console.log(error)
        }
        getAllCompanies();
    }

    const getAllCompanies = () => {
        // const client = axios.create({
        //     baseURL: "https://neets-mnt-backend.azurewebsites.net/api/sv/company/all"
        //     // baseURL: "http://localhost:8080/api/company/all"
        // });
        // client.get('?_limit=10').then((response) => {
        //     console.log(response.data)
        //     setCompanies(response.data);
        // });
        axiosInstance.get("/api/sv/company/all?_limit=10")
            .then((response) => setCompanies(response.data));
    }
    return (
        <>
            {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                <div className="container" style={{ paddingRight: "3em" }}>
                    <div className="row" style={{ flexDirection: "row", justifyContent: "center" }}>
                        <div className="blog-sidebar" >
                            <aside className="widget widget-trend-post" onClick={addNewCompany} style={{cursor:"pointer"}} >
                                <div className="service-download-widget" style={{textAlign: 'center'}}>
                                    <h3><FaPlusCircle /> Adauga firma</h3>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            )}
            <div id="articles" className="row">
                {companies.map((company,) => {
                    return (
                        <>
                            {company.isPublic && localStorage.getItem('user') !== 'cristina.udateanu@goodstory.ro' &&  (
                                <div className="col-lg-4" key={company.id}>
                                    <div className="post-item-1">
                                        {company.data && (
                                            <img src={`data:${company.type};base64,${company.data}`} alt="" style={{height: '300px'}}/>
                                        )}
                                        {!company.data && (
                                            <img src={blogImg1} alt=""/>
                                        )}

                                        <div className="b-post-details">
                                            <h3 align="center">
                                                <Link to={"/firma?id=" + company.id}>
                                                    {company.name}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' &&  (
                                <div className="col-lg-4" key={company.id}>
                                    <div className="post-item-1">
                                        {company.data && (
                                            <img src={`data:${company.type};base64,${company.data}`} alt="" style={{height: '300px'}}/>
                                        )}
                                        {!company.data && (
                                            <img src={blogImg1} alt="" />
                                        )}
                                        <div className="b-post-details">
                                            <h3 align="center">
                                                {/*<a href={"/firma?id=" + company.id}>*/}
                                                {/*    {company.name}*/}
                                                {/*</a>*/}
                                                <Link to={"/firma?id=" + company.id}>
                                                    {company.name}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )
                })}
            </div>
        </>
    );
};

export default Firme;
