import React from 'react';
import aboutThumb from "../../assets/images/about-thumb-2.png";
import aboutThumb3 from "../../assets/images/about-thumb-3.png";

const CampanieDeInformareSiPromovare = ({ className }) => {
    return (
        <>
            <section className={`appie-download-3-area pt-100 ${className || ''}`} id="download" style={{paddingBottom: '20px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Campanie de informare si promovare</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-download-3-box wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="content">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                                Campania de informare si promovare are in vedere identificarea persoanelor cu vârsta intre 16 si 29
                                                de ani care nu sunt ocupati si nu urmeaza nici o forma de educatie sau formare in vederea
                                                directionarii acestora catre SPO in vederea inregistrarii si profilarii.
                                            </p>
                                            <br/>
                                            <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                                Cu ocazia acestei campanii, tinerii vor fi informati si cu privire la oportunitatile de formare
                                                profesionala din cadrul proiectului si celelalte beneficii pentru a fi motivati sa se duca la SPO sa se
                                                inregistreze.
                                            </p>
                                        <br/>
                                            <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                                Campania este dedicata urmatoarelor grupuri:
                                            </p>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="appie-how-it-work-content">
                                                <div className="row" >
                                                    <div className="col-sm-12">
                                                        <div className="how-it-work-box" >
                                                            <span><i className="fal fa-users" /></span>
                                                            <p align="justify">
                                                                Persoane cu varsta intre 16 si 29 de ani care nu urmeaza nici o forma de educatie sau formare si
                                                                care nu sunt ocupate pe piata muncii, care sunt inregistrate si profilate de catre SPO. Aceste
                                                                persoane, pe baza criteriilor de eligibilitate stabilite prin Cererea de finantare pot fi selectate si
                                                                integrate in grupul tinta al proiectului.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="how-it-work-box" >
                                                            <span><i className="fal fa-users" /></span>
                                                            <p align="justify">
                                                                Persoane cu varsta intre 16 si 29 de ani care nu urmeaza nici o forma de educatie sau formare si
                                                                care nu sunt ocupate pe piata muncii ce nu sunt inregistrate si profilate de catre SPO. Aceste
                                                                persoane vor fi directionate catre SPO in vederea inregistrarii si profilarii in conformitate cu
                                                                normele legale in vigoare. Aceste persoane pot fi selectate si inregistrate in grupul tinta dupa ce
                                                                SPO inregistreaza si profileaza aceste persoane.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <br/><br/>
                                            <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                                Astfel, actiunile realizate in cadrul campaniei vor cuprinde:
                                            </p>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="appie-how-it-work-content">
                                                <div className="row" >
                                                    <div className="col-sm-12">
                                                        <div className="how-it-work-box" >
                                                            <span><i className="fal fa-info-circle" /></span>
                                                            <p align="justify">
                                                                Organizarea a 15 de sesiuni de informare in regiune Sud Vest Oltenia privind identificarea
                                                                tinerilor NEETS, unde participantii vor obtine informatii detaliate referitoare la beneficiile pe
                                                                care acestia le au facand parte din acest program, conditiile de eligibiliate si mai multe
                                                                informatii necesare participarii si desfasurii activitatii.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="how-it-work-box" >
                                                            <span><i className="fal fa-info-circle" /></span>
                                                            <p align="justify">
                                                                Multe detalii, gasiti pe pagina de Facebook a proiectului.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<section className="appie-about-3-area pt-100 pb-100" id="features">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row align-items-center" >*/}
            {/*            <div className="col-lg-12" >*/}
            {/*                <div className="appie-section-title text-center" >*/}
            {/*                    <h3 className="appie-title" align="center">Campanie de informare si promovare</h3>*/}
            {/*                </div>*/}
            {/*                <div className="appie-">*/}
            {/*                <p align="justify">*/}
            {/*                    Campania de informare si promovare are in vedere identificarea persoanelor cu vârsta intre 16 si 29*/}
            {/*                    de ani care nu sunt ocupati si nu urmeaza nici o forma de educatie sau formare in vederea*/}
            {/*                    directionarii acestora catre SPO in vederea inregistrarii si profilarii.*/}
            {/*                </p>*/}
            {/*                <p align="justify">*/}
            {/*                    Cu ocazia acestei campanii, tinerii vor fi informati si cu privire la oportunitatile de formare*/}
            {/*                    profesionala din cadrul proiectului si celelalte beneficii pentru a fi motivati sa se duca la SPO sa se*/}
            {/*                    inregistreze.*/}
            {/*                </p>*/}
            {/*                <p align="justify">*/}
            {/*                    Campania este dedicata urmatoarelor grupuri:*/}
            {/*                </p>*/}
            {/*                <p align="justify">*/}
            {/*                    - Persoane cu varsta intre 16 si 29 de ani care nu urmeaza nici o forma de educatie sau formare si*/}
            {/*                    care nu sunt ocupate pe piata muncii, care sunt inregistrate si profilate de catre SPO. Aceste*/}
            {/*                    persoane, pe baza criteriilor de eligibilitate stabilite prin Cererea de finantare pot fi selectate si*/}
            {/*                    integrate in grupul tinta al proiectului.*/}

            {/*                </p>*/}
            {/*                <p align="justify">*/}
            {/*                    - Persoane cu varsta intre 16 si 29 de ani care nu urmeaza nici o forma de educatie sau formare si*/}
            {/*                    care nu sunt ocupate pe piata muncii ce nu sunt inregistrate si profilate de catre SPO. Aceste*/}
            {/*                    persoane vor fi directionate catre SPO in vederea inregistrarii si profilarii in conformitate cu*/}
            {/*                    normele legale in vigoare. Aceste persoane pot fi selectate si inregistrate in grupul tinta dupa ce*/}
            {/*                    SPO inregistreaza si profileaza aceste persoane.*/}
            {/*                </p>*/}
            {/*                <p align="justify">*/}
            {/*                    Astfel, actiunile realizate in cadrul campaniei vor cuprinde:*/}
            {/*                </p>*/}
            {/*                <p align="justify">*/}
            {/*                    - Organizarea a 15 de sesiuni de informare in regiune Sud Vest Oltenia privind identificarea*/}
            {/*                    tinerilor NEETS, unde participantii vor obtine informatii detaliate referitoare la beneficiile pe*/}
            {/*                    care acestia le au facand parte din acest program, conditiile de eligibiliate si mai multe*/}
            {/*                    informatii necesare participarii si desfasurii activitatii.*/}
            {/*                </p>*/}
            {/*                <p align="justify">*/}
            {/*                    - Multe detalii, gasiti pe pagina de Facebook a proiectului.*/}
            {/*                </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </>
    );
};

export default CampanieDeInformareSiPromovare;
