import React, {useEffect} from 'react';
import StickyMenu from "../../lib/StickyMenu";
import {atom, useAtom} from "jotai";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import Login from "./Login";

export const userAtom = atom(null);

const PaginaLogin = () => {
    const [userConnectedAtom, setUserConnectedAtom] = useAtom(userAtom);

    useEffect(() => {
        StickyMenu();
    });

    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <Login />
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaLogin;
