import React, {useEffect} from 'react';
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import Home from "./components/Home/Home";
import ScrollToTop from "./components/Helper/ScrollToTop";
import PaginaArticole from "./components/Articole/PaginaArticole";
import PaginaArticol from "./components/Articole/PaginaArticol";
import PaginaConcurs from "./components/Concurs/PaginaConcurs";
import PaginaContact from "./components/Contact/PaginaContact";
import PaginaCurs from "./components/Cursuri/PaginaCurs";
import PaginaCursuri from "./components/Cursuri/PaginaCursuri";
import PaginaLogin from "./components/Login/PaginaLogin";
import PaginaParteneri from "./components/Parteneri/PaginaParteneri";
import PaginaConcursPlanDeAfaceri from "./components/ConcursPlanDeAfaceri/PaginaConcursPlanDeAfaceri";
import PaginaFirme from "./components/Firme/PaginaFirme";
import PaginaNoutati from "./components/Noutati/PaginaNoutati";
import PaginaFirma from "./components/Firme/PaginaFirma";
import PaginaMaterialeSuport from "./components/MaterialeSuport/PaginaMaterialeSuport";
import CalendarConcurs from "./components/ConcursPlanDeAfaceri/CalendarConcurs";
import ListaCastigatori from "./components/ConcursPlanDeAfaceri/ListaCastigatori";
import PaginaMonitorizareProiect from "./components/MonitorizareProiect/PaginaMonitorizareProiect";
import PaginaIncarcaPlanDeAfaceri from "./components/IncarcarePlanDeAfaceri/PaginaIncarcaPlanDeAfaceri";
import PaginaDocumenteAdmin from "./components/DocumenteIncarcateDeAntreprenori/PaginaDocumenteAdmin";
import ProtectedRouteUser from "./components/ProtectedRouteUser";
import ProtectedRouteAdmin from "./components/ProtectedRouteAdmin";
import FormularInscriere from "./components/Formular Inscriere/FormularInscriere";
import PaginaFormularInscriere from "./components/Formular Inscriere/PaginaFormularInscriere";
import PaginaJoburi from "./components/LocuriDeMunca/PaginaJoburi";
import JoburiAuto from "./components/LocuriDeMunca/JoburiAuto";
import ProtectedJobRouteAdmin from "./components/ProtectedJobRouteAdmin";
import PaginaCvuriAngajare from "./components/CvuriAngajare/PaginaCvuriAngajare";

const AppRoutes = () => {
    // const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 2000);
    // });
    return (
        <>
            {/*{loading && (*/}
            {/*    <div className={`appie-loader ${loading ? 'active' : ''}`}>*/}
            {/*        <Loader />*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*<div className={`appie-visible ${loading === false ? 'active' : ''}`}>*/}
            <Router>
                <ScrollToTop>
                    <Routes>
                        <Route path="/" element={ <Home/> } />
                        <Route path="/formular" element={<PaginaFormularInscriere/>} />
                        <Route path="/materiale-suport" element={ <PaginaCursuri/> } />
                        <Route path="/material" element={<PaginaCurs/>} />
                        {/*<Route path="/curs" element={<PaginaCurs/>} />*/}
                        {/*<Route exact path="/about-us" component={AboutUs} />*/}
                        <Route path="/contact" element={<PaginaContact/>} />
                        {/*<Route exact path="/error" component={Error} />*/}
                        {/*<Route exact path="/about-us-another" component={AboutUsTwo} />*/}
                        <Route path="/locuri-de-munca" element={<PaginaJoburi/>} />
                        <Route path="/job" element={ <ProtectedJobRouteAdmin/> } />
                        <Route path="/joburi-auto" element={ <JoburiAuto/> } />
                        {/*<Route path="/cursuri" element={<PaginaCursuri/>} />*/}
                        {/*<Route path="/parteneri" element={<PaginaParteneri/>} />*/}
                        <Route path="/login" element={<PaginaLogin/>} />
                        {/*<Route path="/concurs" element={<PaginaConcurs/>} />*/}
                        <Route path="/concurs-plan-de-afaceri" element={<PaginaConcursPlanDeAfaceri/>} />
                        <Route path="/firme" element={<PaginaFirme/>} />
                        <Route path="/noutati" element={<PaginaNoutati/>} />
                        <Route path="/firma" element={<PaginaFirma/>} />
                        {/*<Route path="/materiale-suport" element={<PaginaMaterialeSuport/>} />*/}
                        <Route path="/calendar-concurs" element={<CalendarConcurs/>} />
                        <Route path="/castigatori" element={<ListaCastigatori/>} />
                        <Route path="/monitorizare-proiect" element={<PaginaMonitorizareProiect/>} />
                        <Route path="/cvuri-angajare" element={<PaginaCvuriAngajare/>} />

                        <Route path="/incarca-plan-de-afaceri" element={<ProtectedRouteUser />} />
                        <Route path="/documente-admin" element={<ProtectedRouteAdmin/>} />
                        <Route path="/grup-tinta" element={<ProtectedRouteAdmin/>} />
                        <Route path="/*" element={<Home/>} />
                    </Routes>
                </ScrollToTop>
            </Router>
            {/*</div>*/}
        </>
    );
};

export default AppRoutes;
