import React, {useEffect, useState} from 'react';
import useToggle from "../../Hooks/useToggle";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import Firme from "../Firme/Firme"
import axiosInstance from "../Helper/AxiosHelper";
import Drawer from "../Mobile/Drawer";

const PaginaFirme = () => {
    const [drawer, drawerAction] = useToggle(false);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/sv/company/all').then((response) => {
            setCompanies(response.data);
        })
    }, []);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-7">
                            <Firme companies={companies} setCompanies={setCompanies}/>
                        </div>
                        {/*<div className="col-lg-4 col-md-5">*/}
                        {/*    /!*<CursuriSideBar courses={courses} setCourses={setCourses}/>*!/*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaFirme;
