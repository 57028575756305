import React, {useState} from 'react';
import axios from "axios";
import {FaCheckCircle, FaFileUpload, FaPlusCircle, FaStop} from "react-icons/fa";
import axiosInstance from "../Helper/AxiosHelper";

const FormularIncarcare = () => {
    const [selectedFile, setSelectedFile] = useState();

    const [isShown, setIsShown] = useState(false)

    const [isShownError, setIsShownError] = useState(false);

    const handleChange = (e) => {
        // setDocuments({...documents, [e.target.] : e.target.value});
        // console.log(documents);
        // console.log(documents.length);

        setSelectedFile(e.target.files[0]);
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        const formData = new FormData();
        // selectedFile.forEach(file => {
        formData.append("selectedFile", selectedFile);
        formData.append("user", localStorage.getItem("user"));
        // })
        try {
            await axiosInstance.post("/api/sv/files/upload/business-plan", formData, {
                headers: { "Content-Type": "multipart/form-data" },

            })
            // const response = await axios({
            //     method: "post",
            //     url: "https://neets-mnt-backend.azurewebsites.net/api/sv/files/upload/business-plan",
            //     // url: "http://localhost:8080/api/files/upload/business-plan",
            //     data: formData,
            //     headers: { "Content-Type": "multipart/form-data" },
            // });
            setIsShown(true);
            setIsShownError(false);
        } catch(error) {
            console.log(error)
            setIsShown(false)
            setIsShownError(true)
        }

    }
    return (
            <div className="container" style={{paddingTop: '30px'}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="service-details-sidebar mr-50">
                            <div className="service-category-widget">
                                {!isShown && !isShownError && (
                                    <form onSubmit={handleSubmit} align="center">
                                        <label htmlFor="filePicker1" style={{cursor:"pointer"}}>
                                            <div className="blog-sidebar">
                                                <aside className="widget widget-trend-post" >
                                                        <h3><FaFileUpload /></h3>
                                                        <h3> Incarca planul de afaceri</h3>
                                                </aside>
                                            </div>
                                            <input id="filePicker1" type="file" name="file1" onChange={handleChange} style={{display: "none"}}></input>
                                        </label>
                                        {selectedFile && (
                                            <div>
                                                <button className="main-btn" type="submit">
                                                    <i className="fal fa-upload" />&nbsp;Trimite
                                                </button>
                                            </div>
                                        )}
                                        {/*<input className="main-btn" type="submit" value="Upload File">*/}
                                        {/*</input>*/}
                                    </form>
                                )}
                                {isShown && (
                                    <div className="blog-sidebar" align="center">
                                        <h3 style={{color: 'green'}}><FaCheckCircle /></h3>
                                        <h3>Planul de afaceri a fost trimis cu succes!</h3>
                                    </div>
                                )}
                                {isShownError && (
                                    <div className="blog-sidebar" align="center">
                                        <h3 style={{color: 'red'}}><FaStop /></h3>
                                        <h3>Incarcarea a esuat!</h3>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default FormularIncarcare;
