import React from 'react';
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import FormularInscriere from "./FormularInscriere";
import drawer from "../Mobile/Drawer";
import useToggle from "../../Hooks/useToggle";

const PaginaFormularInscriere = () => {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-7">
                            <FormularInscriere />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaFormularInscriere;
