import React from 'react';
import axios from 'axios';
import {FaPlusCircle} from "react-icons/fa";
import BlogImg1 from "../../assets/images/blog/p1.jpg";
import blogImg1 from "../../assets/images/blog/1.jpg";
import axiosInstance from "../Helper/AxiosHelper";

const CursuriSideBar = ({courses, setCourses}) => {
    const createNewCourse = async(e) => {
        e.preventDefault();
        const course = JSON.stringify({
            title : "Titlul materialului",
            text : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            isPublic : false,
            format : "Format",
            materialType : "Tip material"
        })
        try {
            const response = await axiosInstance.post("/api/sv/material/add", course, {
                headers: { "Content-Type": "application/json" }
            });
            // const response = await axios({
            //     method: "POST",
            //     url: "https://neets-mnt-backend.azurewebsites.net/api/sv/material/add",
            //     // url: "http://localhost:8080/api/material/add",
            //     data: course,
            //     headers: { "Content-Type": "application/json" },
            // });
            const data1 = await response;
        } catch(error) {
            console.log(error)
        }
        getAllCourses();
    }

    const getAllCourses = () => {
        // const client = axios.create({
        //     baseURL: "https://neets-mnt-backend.azurewebsites.net/api/sv/material/all"
        //     // baseURL: "http://localhost:8080/api/material/all"
        // });
        // client.get('?_limit=10').then((response) => {
        //     setCourses(response.data);
        // });
        axiosInstance.get("/api/sv/material/all?_limit=10")
            .then((response) => setCourses(response.data));
    }
    return (
        <div className="blog-sidebar">
            {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                <aside className="widget widget-trend-post" onClick={createNewCourse} style={{cursor:"pointer"}}>
                    <div className="service-download-widget" style={{textAlign: 'center'}}>
                        <h3><FaPlusCircle /> Adauga material</h3>
                    </div>
                </aside>
            )}
            <aside className="widget widget-search">
                <form className="search-form">
                    <input type="search" name="s" placeholder="Cauta..." />
                    <button type="submit">
                        <i className="fal fa-search"></i>
                    </button>
                </form>
            </aside>
            <aside className="widget widget-trend-post">
                <h3 className="widget-title">Ultimele materiale</h3>
                {courses.slice(0,5).map((course) => {
                    if (localStorage.getItem("user") === "cristina.udateanu@goodstory.ro") {
                        return (
                            <div className="popular-post" key={course.id}>
                                <a href={"/material?id=" + course.id}>
                                    {course.data && (
                                        <img src={`data:${course.type};base64,${course.data}`} alt="" />
                                    )}
                                    {!course.data && (
                                        <img src={BlogImg1} alt="" />
                                    )}
                                </a>
                                <h5>
                                    <a href={"/material?id=" + course.id}>{course.title}</a>
                                </h5>
                            </div>
                        )
                    } else if (localStorage.getItem('user') !== 'cristina.udateanu@goodstory.ro' && course.isPublic) {
                        return (
                            <div className="popular-post" key={course.id}>
                                <a href={"/material?id=" + course.id}>
                                    {course.data && (
                                        <img src={`data:${course.type};base64,${course.data}`} alt="" />
                                    )}
                                    {!course.data && (
                                        <img src={BlogImg1} alt="" />
                                    )}
                                </a>
                                <h5>
                                    <a href={"/material?id=" + course.id}>{course.title}</a>
                                </h5>
                            </div>
                        )
                    }

                })}
            </aside>
        </div>
    );
};

export default CursuriSideBar;
