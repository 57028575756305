import React from 'react';

const Project = ({ className }) => {
    return (
        <>
            <section className={`appie-project-3-area ${className} `} >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="
                appie-project-3-box
                d-block d-md-flex
                justify-content-between
                align-items-center
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                {/*<h4 className="title">*/}
                                {/*    Contact*/}
                                {/*</h4>*/}
                                <p align="justify" style={{color: "white"}}>

                                    <b>Proiectul este cofinanțat din Fondul Social European prin Programul Operațional Capital Uman 2014-2020, Axa prioritară 1 „Inițiativa locuri de muncă pentru tineri, POCU/991/1/3/154573 Operațiune compozită OS. 1.1, 1.2. Pentru informații detaliate despre celelate programe cofinanțate de Uniunea Europeană, vă invităm să vizitați www.fonduri-ue.ro. Conținutul acestui material nu reprezintă în mod obligatoriu poziția oficială a Uniunii Europene sau a Guvernului României.</b>
                                </p>
                                {/*<a className="main-btn">*/}
                                {/*    0727 811 981*/}
                                {/*</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Project;
