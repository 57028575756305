import React from 'react';
import BlogFour from "../../assets/images/blog-4.jpg";
import BlogFive from "../../assets/images/blog-5.jpg";
import BlogSix from "../../assets/images/blog-6.jpg";
import BlogSeven from "../../assets/images/blog-7.jpg";
import {Link} from "react-router-dom";

const SelectareSiInscriereGrupTinta = () => {
    return (
        <section className="appie-blog-3-area pt-90 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Selectare si inscriere in grup tinta</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-blog-item-3 mt-30">
                            <div className="content">
                                <div className="row align-items-center">
                                    <p align="justify" style={{fontSize: '24px'}}>
                                        <b>Prin intermediul activitatii, se va derula un proces de identificare si includere in grupul tinta a
                                            persoanelor dornice sa beneficieze de oportunitatile existente in cadrul proiectului:</b>
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <div className="appie-how-it-work-content">
                                        <div className="row" >
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-graduation-cap" /></span>
                                                    <p align="justify">
                                                        cursuri de
                                                        calificare autorizate ANC
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-graduation-cap" /></span>
                                                    <p align="justify">
                                                        cursuri de competente antreprenoriale si servicii de consultanta
                                                        antreprenoriala destinate unui numar de 56 de persoane care au ca optiune inclusiv angajarea pe
                                                        cont propriu
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-graduation-cap" /></span>
                                                    <p align="justify">
                                                        oportunitati de ocupare pe piata muncii identificate pentru un numar de minim 162 de
                                                        persoane prin mediere pe piata muncii si sprijin in identificarea unui loc de munca etc.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <p align="justify" style={{fontSize: '24px'}}>
                                        <br/>
                                        <br/>
                                        <b>De ce are nevoie fiecare persoana care doreste sa fie inclusa in proiect?</b>
                                    </p>
                                    <p align="justify" style={{fontSize: '20px'}}>
                                        <br/>
                                        Documentele minime care trebuie completate de fiecare persoana care doreste includerea in grupul
                                        tinta vor fi cel putin urmatoarele:
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <div className="appie-how-it-work-content">
                                        <div className="row" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            margin: '0',
                                        }}>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box" >
                                                    <span>1</span>
                                                    <p align="justify">
                                                        formular de inregistrare in grupul tinta
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6" >
                                                <div className="how-it-work-box" >
                                                    <span>2</span>
                                                    <p align="justify">
                                                        declaratie de consimtamant cu privire la prelucrarea datelor cu caracter personal conform Legii
                                                        677/2001
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>3</span>
                                                    <p align="justify">
                                                        declaratie pe propria raspundere de evitare a dublei finantari, declaratie de evitare a conflictului
                                                        de interese, copie BI/CI din care sa reiasa domiciliul sau resedinta in regiunea de implementare
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>4</span>
                                                    <p align="justify">
                                                        copie certificat de nastere/certificat de casatorie (in cazul schimbarii numelui)
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>5</span>
                                                    <p align="justify">
                                                        copie diploma a ultimei forme de invatamant absolvite
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>6</span>
                                                    <p align="justify">
                                                        certificat (adeverinta) somer NEETs eliberata AJOFM
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>7</span>
                                                    <p align="justify">
                                                        acord scris cu privire la includerea in grupul tinta
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>8</span>
                                                    <p align="justify">
                                                        alte documente necesare inscrierii la cursuri, in functie de specificul fiecaruia, conform
                                                        cerintelor ANC etc.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SelectareSiInscriereGrupTinta;
