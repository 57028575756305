import React from 'react';
import {Link, useHistory, useNavigate} from "react-router-dom";
import Scroll, {scroller} from 'react-scroll';

const ScrollLink = Scroll.Link

const Navigation = () => {
    const navigate = useNavigate();
    const scrollTarget = (target) => scroller.scrollTo(target, {smooth: true, duration: 0, spy: true});
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const scrollToHomePage = async (target) => {
        if (window.location.pathname !=='/') {
            await navigate('/');
            await delay(100);
        }
        scrollTarget(target);
    };

    const scrollToPage = async (target) => {
        if (window.location.pathname !=='/concurs-plan-de-afaceri') {
            await navigate('/concurs-plan-de-afaceri');
            await delay(100);
        }
        scrollTarget(target);
    };
    return (
        <>
            <ul>
                <li>
                    <Link to="/">
                        Despre noi <i className="fal fa-angle-down" />
                    </Link>
                    <ul className="sub-menu" style={{maxWidth: '350px', minWidth: '350px'}}>

                        <li onClick={() => scrollToHomePage('activitati-principale')}>
                            <Link>Activitati principale</Link>
                        </li>
                        <li onClick={() => scrollToHomePage('grupul-tinta')}>
                            <Link>Grupul tinta</Link>
                        </li>
                        <li onClick={() => scrollToHomePage('campanie-de-informare')}>
                            <Link>Campanie de informare si promovare</Link>
                        </li>
                        <li onClick={() => scrollToHomePage('selectare-si-inscriere')}>
                            <Link>Selectare si inscriere grup tinta</Link>
                        </li>
                        <li onClick={() => scrollToHomePage('formare-profesionala')}>
                            <Link>Formare profesionala</Link>
                        </li>
                        <li onClick={() => scrollToHomePage('medierea-muncii')}>
                            <Link>Medierea muncii</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="/concurs-plan-de-afaceri">
                        Concurs Planuri Afaceri <i className="fal fa-angle-down" />
                    </Link>
                    <ul className="sub-menu" style={{maxWidth: '300px', minWidth: '300px'}}>
                        <li onClick={() => scrollToPage('concurs1')}>
                            <Link>Concurs</Link>
                        </li>
                        <li onClick={() => scrollToPage('formare-antreprenoriala')}>
                            <Link>Formare Antreprenoriala</Link>
                        </li>
                        <li onClick={() => scrollToPage('calendar-concurs')}>
                            <Link>Calendar concurs</Link>
                        </li>
                        <li>
                            <Link to="/castigatori">Lista castigatori</Link>
                        </li>
                    </ul>
                </li>
                {/*<li>*/}
                {/*    <Link to="/articole">Articole</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <Link to="/cursuri">Cursuri</Link>*/}
                {/*</li>*/}
                <li>
                    <Link to="/firme">Firme</Link>
                </li>
                <li>
                    <Link to="/locuri-de-munca">Locuri de munca</Link>
                </li>
                <li>
                    <Link to="/noutati">Noutati<i className="fal fa-angle-down" /></Link>
                    <ul className="sub-menu" style={{maxWidth: '300px', minWidth: '300px'}}>
                        <li>
                            <Link to="/materiale-suport">Materiale suport</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="/monitorizare-proiect">Implementare proiect</Link>
                </li>
                <li>
                    <Link to="/cvuri-angajare">CV-uri angajare</Link>
                </li>
                <li>
                    <Link to="/contact" >Contact</Link>
                </li>
                {localStorage.getItem('user') && localStorage.getItem('user') !== 'cristina.udateanu@goodstory.ro' && (
                        <li>
                            <Link to="/incarca-plan-de-afaceri" style={{color: 'black'}}>Incarca Plan Afaceri</Link>
                        </li>
                )}
                {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                    <li>
                        <Link to="/documente-admin" style={{color: 'black'}}>Planuri de afaceri</Link>
                    </li>
                )}
                {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                    <li>
                        <Link to="/grup-tinta" style={{color: 'black'}}>Grup Tinta</Link>
                    </li>
                )}
            </ul>

        </>
    );
};

export default Navigation;
