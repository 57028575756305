import React, {useState} from 'react';
import {Link} from "react-router-dom";
import axiosInstance from "../Helper/AxiosHelper";
import {FaPlusCircle} from "react-icons/fa";
import {GiPlainCircle} from "react-icons/gi";
import {FiEdit} from "react-icons/fi";
import {useAtom} from "jotai";
import {userAtom} from "../Login/PaginaLogin";

const Joburi = ({jobs, setJobs}) => {
    const [userFromAtom, setUserFromAtom] = useAtom(userAtom);
    const [isPublic, setIsPublic] = useState(false);
    function selectJobStatus() {
        setIsPublic(!isPublic);
    }

    const addNewJob = async(e) => {
        e.preventDefault();
        const job = JSON.stringify({
            title: "Titlu job",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            isPublic: false,
            link: 'Link'
        })
        try {

            const response = await axiosInstance.post('/api/sv/job/add', job, {
                headers: { "Content-Type": "application/json" }
            })
            const data1 = await response;
        } catch(error) {
            console.log(error)
        }
        getAllCompanies();
    }

    const getAllCompanies = () => {

        axiosInstance.get('/api/sv/job/all').then((response) => {
            console.log(response.data)
            setJobs(response.data);
        });
    }
    return (
        <>
            {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                <div className="container" style={{ paddingRight: "3em", paddingTop: "30px"}}>
                    <div className="row" style={{ flexDirection: "row", justifyContent: "center" }}>
                        <div className="blog-sidebar" >
                            <aside className="widget widget-trend-post" onClick={addNewJob} style={{cursor:"pointer"}} >
                                <div className="service-download-widget" style={{textAlign: 'center'}}>
                                    <h3><FaPlusCircle /> Adauga job</h3>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            )}

            <section className="appie-blog-3-area appie-blog-8-area pt-90 pb-100">
                <div className="container">
                    <div className="row">
                        {jobs.map((job) => {
                            return (
                                <>
                                {job.isPublic && localStorage.getItem('user') !== 'cristina.udateanu@goodstory.ro' && (
                                        <div className="col-lg-12" key={job.id}>
                                            <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                                                <div className="content">
                                                    <h5 className="title">
                                                        <a href={job.link}>
                                                            {job.title}
                                                        </a>
                                                    </h5>
                                                    <div dangerouslySetInnerHTML={{__html: job.description}}>

                                                    </div>
                                                    <div>
                                                        <h5 >
                                                            <a href={job.link} target="_blank">
                                                                Vezi detalii&nbsp;&nbsp;
                                                                <i className="fal fa-long-arrow-right"></i>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                        <div className="col-lg-12" key={job.id}>
                                            <div className="appie-blog-item-3 appie-blog-item-8 mt-30">
                                                <div className="content">
                                                    <div className="row">
                                                        <Link to={"/job?id=" + job.id}>
                                                            <FiEdit />
                                                        </Link>
                                                    </div>
                                                    <div className="row">
                                                        <h5 className="title">
                                                            {job.title}
                                                        </h5>
                                                    </div>
                                                    <div className="row" dangerouslySetInnerHTML={{__html: job.description}}>

                                                    </div>

                                                    <div className="row">
                                                        <div style={{textAlign: 'right'}}>
                                                            <h5 >
                                                                <a href={job.link} target="_blank">
                                                                    Vezi detalii&nbsp;&nbsp;
                                                                    <i className="fal fa-long-arrow-right"></i>
                                                                </a>
                                                            </h5>
                                                        </div>
                                                        {/*<div style={{textAlign: 'left'}} onClick={selectJobStatus} style={{cursor: "pointer"}}>*/}
                                                        {/*    {isPublic*/}
                                                        {/*        ? <h5 style={{ color: 'red'}}><GiPlainCircle /> Ascunde Job-ul</h5>*/}
                                                        {/*        : <h5 style={{ color: 'green'}}><GiPlainCircle /> Publica Job-ul</h5>*/}
                                                        {/*    }*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Joburi;
