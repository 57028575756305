import React, {useEffect, useState} from 'react';
import StickyMenu from "../../lib/StickyMenu";
import useToggle from "../../Hooks/useToggle";
import {useLocation} from "react-router-dom";
import axios from "axios";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import Firma from "./Firma";
import axiosInstance from "../Helper/AxiosHelper";

const PaginaFirma = () => {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    const [company, setCompany] = useState();
    const [isLoading, setLoading] = useState(true);
    const location = useLocation();
    const idParam = location.search.substring(4)
    // const client = axios.create({
    //     baseURL: "https://neets-mnt-backend.azurewebsites.net/api/sv/company"
    //     // baseURL: "http://localhost:8080/api/company"
    // });

    // useEffect(async () => {
    //     console.log(idParam + "in useeffect")
    //     await client.get('/id', {
    //         params: {
    //             id: idParam
    //         }
    //     }).then((response) => {
    //         setCompany(response.data);
    //         setLoading(false);
    //     });
    // }, []);

    useEffect(() => {
        async function fetchData() {
            // await client.get('/id', {
            //     params: {
            //         id: idParam
            //     }
            // }).then(r => {
            //     setCompany(r.data);
            //     setLoading(false);
            // })

            await axiosInstance.get('/api/sv/company/id', {
                params: {
                    id: idParam
                }
            }).then(r => {
                setCompany(r.data);
                setLoading(false);
            })
        }
        fetchData()

    }, []);

    if (isLoading) {
        return <div></div>;
    }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <Firma company={company}/>
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaFirma;
