import React, {useEffect, useState} from 'react';
import axiosInstance from "../Helper/AxiosHelper";
import FileDownload from "js-file-download";
import ConfirmationModal from "../Modal/ConfirmationModal";

const GrupTinta = () => {
    const [uploads, setUploads] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const openModal = (event, id) => {
        event.preventDefault();
        setIsModalOpen(true);
        setSelectedId(id);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedId(null);
    };

    const confirmDeletion = async () => {
        try {
            await axiosInstance.delete(`/api/sv/grup-tinta/${selectedId}`).then(res => {
                if (!(res.status === 200 || res.status === 204)) {
                    throw new Error(`Error! status: ${res.status}`);
                }
            }).catch((error) => console.log(error))
        } catch (error) {
            console.log(error)
        }
        getFiles();
        closeModal();
        // Proceed with deletion logic
        // ...
    };

    useEffect(() => {
        getFiles();
    }, []);

    async function downloadFile(event, upload) {
        event.preventDefault();
        axiosInstance.get(`/api/sv/grup-tinta/download/${upload.id}`, {
            responseType: "blob"
        }).then((res) => {
            const response = res.data;
            FileDownload(response, upload.fileName, response.type)
        })
    }

    function getFiles() {
        axiosInstance.get('/api/sv/grup-tinta/all')
            .then((response) => setUploads(response.data));
    }

    async function uploadFile(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        try {
            await axiosInstance.post('/api/sv/grup-tinta/add', formData, {
                headers: {"Content-Type": "multipart/form-data"},
            }).then(res => {
                if (!(res.status === 200 || res.status === 201)) {
                    throw new Error(`Error! status: ${res.status}`);
                }
            }).catch((error) => console.log(error))
        } catch (error) {
            console.log(error)
        }
        getFiles();
    }

    async function deleteFile() {
        // event.preventDefault();
        // Confirmation dialog
        // const isConfirmed = window.confirm("Esti sigur ca vrei sa stergi acest fisier?");
        // if (!isConfirmed) {
        //     return; // Stop the function if not confirmed
        // }

        try {
            await axiosInstance.delete(`/api/sv/grup-tinta/${selectedId}`).then(res => {
                if (!(res.status === 200 || res.status === 204)) {
                    throw new Error(`Error! status: ${res.status}`);
                }
            }).catch((error) => console.log(error))
        } catch (error) {
            console.log(error)
        }
        getFiles();
    }

    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <label htmlFor="imagePicker"  style={{cursor:"pointer"}}>
                                                <i className="fal fa-upload"></i>Incarca fisier
                                                <input id="imagePicker" type="file" name="file" onChange={uploadFile} style={{display: "none"}}></input>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-sidebar mr-50">

                                <div className="service-category-widget">
                                    <div className="row mb-4">
                                        <div className="col-lg-12">
                                            <div className="text-center">
                                                <p style={{fontSize: '30px'}}><b>Lista fisiere:</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    <ul>
                                        {uploads.map((upload) => {
                                            return(
                                                <li key={upload.id}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <label onClick={(event) => downloadFile(event, upload)} style={{ cursor: "pointer" }}>
                                                            <i className="fal fa-download"></i>{upload.fileName}
                                                        </label>
                                                        {/*<i className="fal fa-trash-alt" style={{ cursor: "pointer" }} onClick={(event) => deleteFile(event, upload.id)}></i>*/}
                                                        <i className="fal fa-trash-alt" style={{ cursor: "pointer" }}
                                                           onClick={(event) => openModal(event, upload.id)}></i>
                                                        <ConfirmationModal
                                                            isOpen={isModalOpen}
                                                            onClose={closeModal}
                                                            onConfirm={confirmDeletion}
                                                            message="Sterge fisierul?" />
                                                    </div>
                                                </li>

                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};

export default GrupTinta;
