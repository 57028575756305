import React from 'react';
import thumb from '../../assets/images/harta.png';

const HeroArticol = () => {
    return (
        <>
            <div className="appie-page-title-area appie-page-service-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-page-title-item">
                                <h3 className="title">
                                    Fonduri nerambursabile <br /> pentru afacerea ta
                                </h3>
                                <div className="thumb">
                                    <img src={thumb} alt="" width="450" height="400"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeroArticol;
