import React from 'react';
import DownloadSection from "../Concurs/DownloadSection";
import Axios from "axios";
import FileDownload from "js-file-download";
import {Link} from "react-router-dom";
import PdfDisplay from "./PdfDisplay";
import axiosInstance from "../Helper/AxiosHelper";

const Etapa1 = () => {

    function downloadFile(e) {
        e.preventDefault();
        let fileName = "";
        if (e.target.innerText === 'Descarca') {
            fileName = "Metodologie_concurs_v4.pdf";
        } else {
            fileName = e.target.innerText;
        }
        console.log(fileName)
        axiosInstance.get(`/api/sv/files/downloads/${fileName}`, {
            responseType:"blob"
        }).then((res) => {
            FileDownload(res.data, `${fileName}`)
        })
        // Axios({
        //     url:`https://neets-mnt-backend.azurewebsites.net/api/sv/files/downloads/${fileName}`,
        //     // url: `http://localhost:8080/api/files/downloads/${fileName}`,
        //     method:"GET",
        //     responseType:"blob"
        // }).then((res) => {
        //     FileDownload(res.data, `${fileName}`)
        // })
    }

    return (
        <section className="appie-blog-3-area pt-90 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Calendar concurs</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-blog-item-3 mt-30">
                            <div className="content">
                                <div className="col-lg-12">
                                    <div className="appie-how-it-work-content">
                                        <div className="row" >
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-calendar-times" /></span>
                                                    <p align="justify">
                                                        Perioada de depunere a candidaturilor: 21 noiembrie 2022 – 02 decembrie 2022
                                                    </p>
                                                    <p align="justify">
                                                        <b>Se prelungeste perioada pentru depunerea planurilor de afaceri pana luni, 5 decembrie 2022, orele 22:00</b>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-calendar-times" /></span>
                                                    <p align="justify">
                                                        Evaluarea planurilor de afaceri: 28 noiembrie 2022 – 9 decembrie 2022
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-calendar-times" /></span>
                                                    <p align="justify">
                                                        Publicare lista intermediara castigatori: 12 decembrie 2022
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-calendar-times" /></span>
                                                    <p align="justify">
                                                        Depunere contestatii: 13 decembrie 2022
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-calendar-times" /></span>
                                                    <p align="justify">
                                                        Raspuns contestatii: 14 decembrie 2022
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-calendar-times" /></span>
                                                    <p align="justify">
                                                        Anuntarea listei finale a castigatorilor: 15 decembrie 2022
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-calendar-times" /></span>
                                                    <p align="justify">
                                                        Infiintare start-up: 16 decembrie – 22 decembrie 2022
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-calendar-times" /></span>
                                                    <p align="justify">
                                                        Semnarea contractelor de subventie: 23 decembrie 2022
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-blog-item-3 mt-30">
                            <div className="content">
                                {/*<div className="row align-items-center">*/}
                                {/*    <PdfDisplay/>*/}
                                {/*</div>*/}
                                <div className="row align-items-center">
                                    <p align="justify" style={{fontSize: '24px'}}>
                                        <br/>
                                        <br/>
                                        <b>Dosarul de concurs contine:</b>
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <div className="appie-how-it-work-content">
                                        <div className="row" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            margin: '0',
                                        }}>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box" >
                                                    <span>1</span>
                                                    <p align="justify">
                                                        OPIS (centralizarea tuturor documentelor dosarului de candidatura)
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6" >
                                                <div className="how-it-work-box" >
                                                    <span>2</span>
                                                    <p align="justify">
                                                        Anexa 1 – Declaratie privind calitatea de asociat a solicitantului
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>3</span>
                                                    <p align="justify">
                                                        Anexa 2 - Declaratie privind evitarea dublei finantari
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>4</span>
                                                    <p align="justify">
                                                        Anexa 3 – Declaratie pe propria raspundere a solicitantului
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>5</span>
                                                    <p align="justify">
                                                        Anexa 4 - Declaratie privind neincadrarea in situatiile prevazute la art.10, art. 11, art.12,
                                                        art.13, art.14 si art.15 din Ordonanta de urgenta a Guvernului nr.66/2011
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>6</span>
                                                    <p align="justify">
                                                        Anexa 5 – Declaratie pe propria raspundere privind datele cu caracter personal si
                                                        dreptul de autor
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>7</span>
                                                    <p align="justify">
                                                        Anexele 6, 6A, 6B, si 6C – Plan de afaceri si buget (semnate pe fiecare pagina de catre
                                                        solicitant).
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>8</span>
                                                    <p align="justify">
                                                        Anexa 7 - Declaratie de eligibilitate
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>9</span>
                                                    <p align="justify">
                                                        Copie dupa actul de identitate al solicitantului, semnata olograf de catre solicitant
                                                        pentru conformitatea cu originalul
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>10</span>
                                                    <p align="justify">
                                                        Cazier judiciar valabil la data depunerii candidaturii
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="how-it-work-box">
                                                    <span>11</span>
                                                    <p align="justify">
                                                        Copie certificat emis de ANC in domeniul competentelor antreprenoriale sau o
                                                        adeverinta care sa dovedeasca faptul ca a absolvit cursul acreditat de competente
                                                        antreprenoriale sustinut de furnizorul din cadrul proiectului
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="appie-blog-item-3 mt-30">
                            <div className="content">
                                <div className="col-lg-12">
                                    <div className="appie-how-it-work-content">
                                        <div className="row align-items-center">
                                            <div className="col-lg-2">
                                                <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                            </div>
                                            <div className="col-lg-8">
                                                {/*<h4 className="appie-title">Listă centralizatoare finală evaluare eligibilitate și conformitate administrativă</h4>*/}
                                                <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                                    Metodologie de organizare a concursului de planuri de afaceri
                                                    in cadrul proiectului
                                                </p>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="content" >
                                                    <button className="main-btn" onClick={downloadFile}>
                                                        <i className="fal fa-download" />Descarca
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{paddingTop: '40px'}}>
                    <div className="col-lg-6">
                        <div className="col-lg-12">
                            <div className="appie-how-it-work-content">
                                <div className="row" >
                                    <div className="col-sm-12">
                                        <p align="justify" style={{fontSize: '24px'}}>
                                            Concursul de planuri de afaceri este deschis <b>EXCLUSIV</b> persoanelor inscrise in grupul tinta al
                                            proiectului, care indeplinesc cumulativ urmatoarele cerinte:
                                        </p>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="how-it-work-box" >
                                            <span><i className="fas fa-users" /></span>
                                            <p align="justify">
                                                Se incadreaza in categoria de grup tinta eligibil.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="how-it-work-box" >
                                            <span><i className="fas fa-users" /></span>
                                            <p align="justify">
                                                Intocmesc si depun dosar de candidatura in cadrul concursului de planuri de afaceri,
                                                plan care nu a mai fost finantat din alte surse provenite din Fondul Social European
                                                (pentru evitarea dublei finantari).
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="how-it-work-box" >
                                            <span><i className="fas fa-users" /></span>
                                            <p align="justify">
                                                In cazul finantarii planului de afaceri, isi asuma ca va fi reprezentantul legal al
                                                intreprinderii legal constituite in România, fiind direct responsabili de pregatirea si
                                                implementarea planului de afaceri si neactionand ca intermediar pentru planul de
                                                afaceri propus a fi finantat.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="team-btn text-center mt-50" >
                                            <Link to="/login">
                                                <button className="main-btn" >
                                                    <i className="fal fa-file-pdf"></i> Incarca planul tau de afaceri
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="service-details-sidebar mr-50">
                            <div className="service-category-widget">
                                <ul>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>_Opis.docx
                                        </label>
                                    </li>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>Anexa 1_Declaratie privind calitate de asociat.docx
                                        </label>
                                    </li>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>Anexa 2_Declaratie evitare dubla finantare.docx
                                        </label>
                                    </li>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>Anexa 3_Declaratie pe propria raspundere.docx
                                        </label>
                                    </li>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>Anexa 4_Declaratie conflict interese.docx
                                        </label>
                                    </li>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>Anexa 5_Declaratie date pers_drepturi autor.docx
                                        </label>
                                    </li>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>Anexa 6A Finantarea afacerii _NEETS.xlsx
                                        </label>
                                    </li>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>Anexa 6_Plan de afaceri competitie.docx
                                        </label>
                                    </li>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>Anexa 6 Schema de minimis NEETS I.pdf
                                        </label>
                                    </li>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>Anexa-6B_Fluxul-de-numerar.xlsx
                                        </label>
                                    </li>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>Anexa-6C_-Contul-de-profit-si-pierdere-prescurtat.xlsx
                                        </label>
                                    </li>
                                    <li>
                                        <label onClick={downloadFile} style={{cursor:"pointer"}} >
                                            <i className="fal fa-download"></i>Anexa 7_Declaratie de eligibilitate_Instructiunea_19_Anexa_1.docx
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Etapa1;
