import React from 'react';
import PaginaDocumenteAdmin from "./DocumenteIncarcateDeAntreprenori/PaginaDocumenteAdmin";
import {Navigate} from "react-router-dom";
import PaginaJobForEdit from "./LocuriDeMunca/PaginaJobForEdit";

const ProtectedJobRouteAdmin = () => {
    if (localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro') {
        return <PaginaJobForEdit />
    }
    return <Navigate to="/joburi" />
};

export default ProtectedJobRouteAdmin;
