import React, {useEffect, useState} from 'react';
import {GiPlainCircle} from "react-icons/gi";
import thumb from "../../assets/images/service-details-thumb.jpg";
import Quill from "../../lib/Quill";
import axiosInstance from "../Helper/AxiosHelper";
import FileDownload from "js-file-download";
import {useLocation} from "react-router-dom";

const Job = () => {
    const [isShown, setIsShown] = useState(false);
    const [isTitleEditable, setIsTitleEditable] = useState(false);

    const [job, setJob] = useState(null);
    const location = useLocation();
    const idParam = location.search.substring(4)

    useEffect(() => {
        async function fetchData() {
            await axiosInstance.get('/api/sv/job/id', {
                params: {
                    id: idParam
                },
            }).then(r => {
                setJob(r.data);
            })
        }
        fetchData()

    }, []);



    const showEditor = (e) => {
        if (localStorage.getItem('user') !== null) {
            if (isShown) {
                updateText(e).then(r => console.log(r));
            }
            setIsShown(!isShown);
        }
    };


    const updateText = async(e) => {
        e.preventDefault();
        setIsTitleEditable(false);
        const stringHtml = document.getElementById('tryEdit').innerHTML.toString();
        const jobJson = JSON.stringify({
            title : job.title,
            description : stringHtml,
            link : job.link,
            isPublic : job.isPublic,
        })
        try {

            const response = await axiosInstance.patch('/api/sv/job/update', jobJson, {
                headers: {"Content-Type": "application/json"},
                params: {
                    id: job.id
                }
            })
            const data1 = await response;

        } catch(error) {
            console.log(error)
        }
    }


    function handleTitleChange(e) {
        setJob({ ...job, title: e.target.value })
    }

    async function selectMaterialStatus(e) {
        e.preventDefault()
        const jobJson = JSON.stringify({
            title : job.title,
            description : job.description,
            link : job.link,
            isPublic : !job.isPublic,
        })
        try {
            const response = await axiosInstance.patch('/api/sv/job/update', jobJson, {
                headers: {"Content-Type": "application/json"},
                params: {
                    id: job.id
                }
            })
            const data1 = await response;
            setJob(data1.data)
        } catch(error) {
            console.log(error)
        }
    }

    function handleLinkChange(e) {
        setJob({ ...job, link: e.target.value })
    }

    if (!job) {
        return <div></div>;
    }

    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                    <div className="service-category-widget" style={{textAlign: 'center'}}>
                                        <div onClick={selectMaterialStatus} style={{textAlign: 'center', cursor:"pointer"}} >
                                            {job.isPublic
                                                ? <h3 style={{ color: 'red'}}><GiPlainCircle /> Ascunde Job</h3>
                                                : <h3 style={{ color: 'green'}}><GiPlainCircle /> Publica Job</h3>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                        <form onSubmit={updateText}>
                                            <ul>
                                                <li>
                                                    <label htmlFor="filePicker4">
                                                        <i className="fal fa-link"></i>
                                                        <input id="filePicker4" type="text" placeholder={job.link} name="file2" style={{maxWidth: '190px'}} onChange={handleLinkChange}>
                                                        </input>
                                                        <button type="submit" >
                                                            Save
                                                        </button>
                                                    </label>
                                                </li>
                                            </ul>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div className="service-details-content">
                                {isShown && (
                                    <div>
                                        <Quill
                                            actualText={{
                                                container: document.getElementById('tryEdit'),
                                            }}
                                        />
                                        <button type="submit" onClick={showEditor}>
                                            Save
                                        </button>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="appie-section-title text-center" style={{paddingTop: '50px'}}>
                                            {isTitleEditable && (
                                                <form onSubmit={updateText}>
                                                    <label htmlFor="filePicker1">
                                                        <input id="filePicker1" type="text" placeholder={job.title} name="file1" onChange={handleTitleChange}>
                                                        </input>
                                                        <button type="submit" >
                                                            Save
                                                        </button>
                                                    </label>
                                                </form>
                                            )}
                                            {!isTitleEditable && (
                                                <h3 className="appie-title" onClick={() => setIsTitleEditable(!isTitleEditable)}>{job.title}</h3>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div id="tryEdit" className="content text-justify" onClick={showEditor} dangerouslySetInnerHTML={{__html: job.description}}>
                                    {/*<p>*/}
                                    {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Job;
