import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import heroThumb from '../../assets/images/headpic.png';
import {scroller} from "react-scroll";

function Drawer({ drawer, action, lang }) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('home');
    const handler = (e, value) => {
        // e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        }
    };

    const navigate = useNavigate();
    const scrollTarget = (target) => scroller.scrollTo(target, {smooth: true, duration: 0, spy: true});
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const scrollToHomePage = async (target) => {
        if (window.location.pathname !=='/') {
            await navigate('/');
            await delay(100);
        }
        scrollTarget(target);
    };

    const scrollToPage = async (target) => {
        if (window.location.pathname !=='/concurs-plan-de-afaceri') {
            await navigate('/concurs-plan-de-afaceri');
            await delay(100);
        }
        scrollTarget(target);
    };

    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className={`offcanvas_menu_wrapper ${
                                    drawer ? 'active' : ''
                                }`}
                            >
                                <div className="canvas_close">
                                    <a href="src/components/Mobile/Drawer#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={heroThumb} alt="" />
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li
                                            onClick={(e) => handler(e, 'home')}
                                            id="home"
                                            className="menu-item-has-children active"
                                        >
                                            <span className="menu-expand">
                                                <i className="fa fa-angle-down"></i>
                                            </span>
                                            <Link to="/">
                                                Despre noi
                                            </Link>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    height:
                                                        item === 'home' ? itemSize : '0px',
                                                }}
                                            >
                                                <li onClick={() => scrollToHomePage('activitati-principale')} style={{cursor:"pointer"}}>
                                                    <Link>Activitati principale</Link>

                                                </li>
                                                <li onClick={() => scrollToHomePage('grupul-tinta')}>
                                                    <Link>Grupul tinta</Link>
                                                </li>
                                                <li onClick={() => scrollToHomePage('campanie-de-informare')}>
                                                    <Link>Campanie de informare si promovare</Link>
                                                </li>
                                                <li onClick={() => scrollToHomePage('selectare-si-inscriere')}>
                                                    <Link>Selectare si inscriere grup tinta</Link>
                                                </li>
                                                <li onClick={() => scrollToHomePage('formare-profesionala')}>
                                                    <Link>Formare profesionala</Link>
                                                </li>
                                                <li onClick={() => scrollToHomePage('medierea-muncii')}>
                                                    <Link>Medierea muncii</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'concurs-plan')}
                                            id="concurs-plan"
                                            className="menu-item-has-children active"
                                        >
                                            <span className="menu-expand">
                                                <i className="fa fa-angle-down"></i>
                                            </span>
                                            <Link to="/concurs-plan-de-afaceri">
                                                Concurs Planuri de Afaceri
                                            </Link>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    height:
                                                        item === 'concurs-plan' ? itemSize : '0px',
                                                }}
                                            >
                                                <li onClick={() => scrollToPage('concurs1')}>
                                                    <Link>Concurs</Link>
                                                </li>
                                                <li onClick={() => scrollToPage('formare-antreprenoriala')}>
                                                    <Link>Formare Antreprenoriala</Link>
                                                </li>
                                                <li onClick={() => scrollToPage('calendar-concurs')}>
                                                    <Link>Calendar concurs</Link>
                                                </li>
                                                <li>
                                                    <Link to="/castigatori">Lista castigatori</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'firme')}
                                            id="firme"
                                            className="menu-item-has-children active"
                                        >

                                                <Link to="/firme">Firme</Link>

                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'locuri-de-munca')}
                                            id="locuri-de-munca"
                                            className="menu-item-has-children active"
                                        >

                                                <Link to="/locuri-de-munca">Locuri de munca</Link>

                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'noutati')}
                                            id="noutati"
                                            className="menu-item-has-children active"
                                        >
                                            <span className="menu-expand">
                                                <i className="fa fa-angle-down"></i>
                                            </span>
                                            <Link to="/noutati">Noutati</Link>

                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    height:
                                                        item === 'noutati' ? itemSize : '0px',
                                                }}
                                            >
                                                <li>
                                                    <Link to="/materiale-suport">Materiale suport</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'monitorizare-proiect')}
                                            id="monitorizare-proiect"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/monitorizare-proiect">Monitorizare proiect</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'contact')}
                                            id="contact"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/contact">Contact</Link>
                                        </li>
                                        {localStorage.getItem('user') && localStorage.getItem('user') !== 'cristina.udateanu@goodstory.ro' && (
                                            <li
                                                onClick={(e) => handler(e, 'incarca-plan-de-afaceri')}
                                                id="incarca-plan-de-afaceri"
                                                className="menu-item-has-children active"
                                            >
                                                <Link to="/incarca-plan-de-afaceri">Incarca plan de afaceri</Link>
                                            </li>
                                        )}
                                        {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                            <li
                                                onClick={(e) => handler(e, 'documente-admin')}
                                                id="documente-admin"
                                                className="menu-item-has-children active"
                                            >
                                                <Link to="/documente-admin">Planuri de afaceri</Link>
                                            </li>
                                        )}
                                        {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                            <li
                                                onClick={(e) => handler(e, 'grup-tinta')}
                                                id="grup-tinta"
                                                className="menu-item-has-children active"
                                            >
                                                <Link to="/grup-tinta">Grup tinta</Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className="offcanvas-social">
                                    <ul className="text-center">
                                        <li>
                                            <a target="_blank">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info">
                                    <ul>
                                        <li>
                                            <a>
                                                <i className="fal fa-envelope"></i>{' '}
                                                proiecte.crv@yahoo.com
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <i className="fal fa-phone"></i> 0761 490 781
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <i className="fal fa-phone"></i> 0764 626 160
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <i className="fal fa-map-marker-alt"></i>{' '}
                                                Strada Traian 122- 124,
                                                <br/>Bucuresti 021892
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Drawer;
