import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import {DatePicker} from 'reactstrap-date-picker';
import axiosInstance from "../Helper/AxiosHelper";
// import DatePicker from "react-datepicker";

const FormularInscriere = () => {
    const [counties, setCounties] = useState([]);
    const [towns, setTowns] = useState([]);
    const [townsResedinta, setTownsResedinta] = useState([]);
    const [isAnonymousMessage, setIsAnonymousMessage] = useState(false);
    const [isCnpValid, setIsCnpValid] = useState(true);
    const [formData, setFormData] = useState({
        cnp: '',
        nume: '',
        prenume: '',
        nationalitate: 'romana',
        serieCi: '',
        numarCi: '',
        ciEliberatDe: '',
        ciEliberatLaData: '',
        dataNastere: '',
        gen: '',
        stareCivila: '',
        numeAnterior: '',
        judet: '',
        localitate: '',
        adresa: '',
        mediu: '',
        aceeasiCuCi: '',
        judetResedinta: '',
        localitateResedinta: '',
        adresaResedinta: '',
        mediuResedinta: '',
        email: '',
        telefon: '',
        partener: '',
        tipSomer: 'Somer pe o perioada mai mare de 12 luni',
        reprezentare: '',
        numeReprezentare: '',
        stareSanatate: 'nu',
        detaliiStareSanatate: '',
        etnieRoma: 'Da',
        categorieGt: '',
        situatiePePiataFm: '',
        inscrisInEducatie: 'nu',
        inscrisInFormare: 'nu',
        nivelEducatie: '',
        persoanaDezavantajata: '',
        participantiFaraPersoaneOcupate: '',
        participantiCuCopiiInIntretinere: '',
        participantiParinteUnic: '',
        migranti: '',
        participantiOrgineStraina: '',
        minoritatiEtnieRoma: '',
        alteMinoritati: '',
        persoaneCuDizabilitati: '',
        alteCategorii: '',
        persoaneFaraAdapost: '',
        apartinComunitatiMarginalizate: '',
    });

    // useEffect(() => {
    //     getCounties();
    // }, [])
    //
    // async function getCounties() {
    //     await axiosInstance.get('/api/siruta/counties')
    //         .then(r => setCounties(r.data));
    // }
    //
    // async function getTowns(value) {
    //     await axiosInstance.get('/api/siruta/towns', {
    //         params: {
    //             county: value
    //         }
    //     }).then(r => setTowns(r.data));
    // }
    //
    // async function getTownsResedinta(value) {
    //     await axiosInstance.get('/api/siruta/towns', {
    //         params: {
    //             county: value
    //         }
    //     }).then(r => setTownsResedinta(r.data));
    // }

    const onChangeInput = (e) => {
        if (e.target.type === "checkbox") {
            if (e.target.name === "aceeasiCuCi" && e.target.checked) {
                setFormData({...formData, judetResedinta: formData.judet})
                setFormData({...formData, localitateResedinta: formData.localitate})
                setFormData({...formData, adresaResedinta: formData.adresa})
                setFormData({...formData, mediuResedinta: formData.mediu})
            }
            setFormData({...formData, [e.target.name]: (e.target.checked ? "da" : "nu")})
        }
        else {
            setFormData({...formData, [e.target.name]: e.target.value})
        }
        // if (e.target.name === "judet") {
        //     getTowns(e.target.value);
        // }
        // if (e.target.name === "judetResedinta") {
        //     getTownsResedinta(e.target.value);
        // }
        // if (e.target.name === "cnp") { // functie, nu in state
        //     const numbers = e.target.value.match(/\d/g);
        //     // Number(e.target.value)
        //     setIsCnpValid(e.target.value.length === 13 && numbers !== null && numbers.length === e.target.value.length)
        // }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            await axiosInstance.post('/api/sv/form', formData,{
                headers: {"Content-Type": "application/json"}
            }).then(() => setIsAnonymousMessage(true))
        } catch(error) {
            console.log(error)
        }
    }

    const handleDateChange = (event) => {
        const localDate = new Date(event);
        const year = localDate.getFullYear();
        const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
        const day = localDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
        setFormData({ ...formData, ciEliberatLaData: formattedDate });
    };

    if (isAnonymousMessage) {
        return (
            <React.Fragment>
                <div className="page-content" style={{paddingTop: "70px"}}>
                    <Container fluid>
                        <Row className="mb-4">
                            <h1 align="center"><b>Datele dvs. vor fi verificate si va vom oferi asistenta in vederea completarii documentelor.</b></h1>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content" style={{paddingTop: "50px"}}>
                <Container fluid>
                    <Row className="mb-4 justify-content-center">
                        <h1 align="center"><b>FORMULAR INSCRIERE CANDIDAT</b></h1>
                    </Row>
                </Container>
            </div>
            <div className="page-content" style={{paddingTop: "20px"}}>
                <Container fluid>
                    <form onSubmit={handleSubmit}>
                        <Row className="mb-4">
                            <Col xs={6}>
                                <Card  style={{height: "100%"}}>
                                    <CardBody>
                                        <h4 className="card-title"><b>Date identificare</b></h4>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-cnp-input" className="col-md-3 col-form-label">CNP*</Label>
                                            <Col md={9}>
                                                <Input className={isCnpValid ? "" : "is-invalid"} type="text" name="cnp" placeholder="Introdu CNP" value={formData.cnp} id="example-cnp-input" onChange={onChangeInput} />
                                                <div className="invalid-feedback">
                                                    CNP-ul poate contine doar 13 cifre.
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-nume-input" className="col-md-3 col-form-label">Nume*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="nume" placeholder="Introdu nume" value={formData.nume} id="example-nume-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-prenume-input" className="col-md-3 col-form-label">Prenume*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="prenume" placeholder="Introdu prenume" value={formData.prenume} id="example-prenume-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Nationalitate*</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="nationalitate"
                                                    id="hasOption"
                                                    value={formData.nationalitate}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option defaultValue="Romana">Romana</option>
                                                    <option value="Alta">Alta</option>
                                                </Input>
                                                {/*<select className="form-select" name="nationalitate" value={formData.nationalitate} onChange={onChangeInput}>*/}
                                                {/*    /!*<option value="" disabled selected hidden>Selecteaza stare civila</option>*!/*/}
                                                {/*    <option defaultValue="Romana">Romana</option>*/}
                                                {/*    <option value="Alta">Alta</option>*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-serieci-input" className="col-md-3 col-form-label">Serie CI*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="serieCi" value={formData.serieCi} placeholder="Introdu Serie CI" id="example-serieci-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-numarci-input" className="col-md-3 col-form-label">Numar CI*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="numarCi" value={formData.numarCi} placeholder="Introdu Numar CI" id="example-numarci-input" onChange={onChangeInput}/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-cieliberat-input" className="col-md-3 col-form-label">CI eliberat de*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="ciEliberatDe" value={formData.ciEliberatDe} placeholder="Introdu eliberator CI" id="example-cieliberat-input" onChange={onChangeInput}/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-cidata-input" className="col-md-3 col-form-label">CI eliberat la data*</Label>
                                            <Col md={9}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="ciEliberatLaData"
                                                        className="custom-control"
                                                        selected={formData.ciEliberatLaData === '' || formData.ciEliberatLaData === null ? null : new Date(formData.ciEliberatLaData.toLocaleString())}
                                                        onChange={handleDateChange}
                                                        dateFormat="DD-MM-YYYY"
                                                        placeholder="ZZ-LL-AAAA"
                                                    />
                                                    {/*<div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>*/}
                                                    {/*<span>*/}
                                                    {/*    <i className="far fa-calendar"></i>*/}
                                                    {/*</span>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </Col>
                                        </Row>

                                        {formData.cnp.length < 7 && (
                                            <Row className="mb-3">
                                                <Label htmlFor="example-datanastere-input" className="col-md-3 col-form-label" style={{color: "red"}}>Data nastere</Label>
                                                <Col md={9}>
                                                    <Input type="text" value="" name="dataNastere" readonly="readonly" id="example-datanastere-input" onChange={onChangeInput}/>
                                                </Col>
                                            </Row>
                                        )}
                                        {formData.cnp.length >= 7 && (
                                            <Row className="mb-3">
                                                <Label htmlFor="example-datanastere-input" className="col-md-3 col-form-label" style={{color: "red"}}>Data nastere</Label>
                                                <Col md={9}>
                                                    <Input type="text" value={formData.cnp.charAt(5) +
                                                        formData.cnp.charAt(6) +
                                                        "-" + formData.cnp.charAt(3) +
                                                        formData.cnp.charAt(4) +
                                                        "-" + (formData.cnp.charAt(0) === '1' || formData.cnp.charAt(0) === '2' ? "19" : "20") +
                                                        formData.cnp.charAt(1) +
                                                        formData.cnp.charAt(2)} readonly="readonly" name="dataNastere" id="example-datanastere-input" onChange={onChangeInput}/>
                                                </Col>
                                            </Row>
                                        )}
                                        {(formData.cnp.charAt(0) === '1' || formData.cnp.charAt(0) === '5') && (
                                            <Row className="mb-3">
                                                <Label htmlFor="example-gen-input" className="col-md-3 col-form-label" style={{color: "red"}}>Gen</Label>
                                                <Col md={9}>
                                                    <Input type="text" value="Masculin" readonly="readonly" id="example-gen-input" onChange={onChangeInput}/>
                                                </Col>
                                            </Row>
                                        )}
                                        {(formData.cnp.charAt(0) === '2' || formData.cnp.charAt(0) === '6')  && (
                                            <Row className="mb-3">
                                                <Label htmlFor="example-gen-input" className="col-md-3 col-form-label" style={{color: "red"}}>Gen</Label>
                                                <Col md={9}>
                                                    <Input type="text" value="Feminin" readonly="readonly" id="example-gen-input" onChange={onChangeInput}/>
                                                </Col>
                                            </Row>
                                        )}
                                        {(formData.cnp.charAt(0) !== '1' &&
                                            formData.cnp.charAt(0) !== '2' &&
                                            formData.cnp.charAt(0) !== '5' &&
                                            formData.cnp.charAt(0) !== '6') && (
                                            <Row className="mb-3">
                                                <Label htmlFor="example-gen-input" className="col-md-3 col-form-label" style={{color: "red"}}>Gen</Label>
                                                <Col md={9}>
                                                    <Input type="text" value="" readonly="readonly" id="example-gen-input" onChange={onChangeInput}/>
                                                </Col>
                                            </Row>
                                        )}
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Stare civila*</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="stareCivila"
                                                    value={formData.stareCivila}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option value="" disabled selected hidden>Selecteaza stare civila</option>
                                                    <option value="casatorit">Casatorit</option>
                                                    <option value="necasatorit">Necasatorit</option>
                                                </Input>
                                                {/*<select className="form-select" value={formData.stareCivila} name="stareCivila" onChange={onChangeInput}>*/}
                                                {/*    <option value="" disabled selected hidden>Selecteaza stare civila</option>*/}
                                                {/*    <option value="casatorit">Casatorit</option>*/}
                                                {/*    <option value="necasatorit">Necasatorit</option>*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-numeanterior-input" className="col-md-3 col-form-label">Nume anterior</Label>
                                            <Col md={9}>
                                                <Input type="text" name="numeAnterior" value={formData.numeAnterior} placeholder="Introdu nume anterior" id="example-numeanterior-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={6}>
                                <Card style={{height: "100%"}}>
                                    <CardBody >
                                        <h4 className="card-title"><b>Adresa CI</b></h4>

                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Judet*</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="judet"
                                                    value={formData.judet}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option value="" disabled selected hidden>Selecteaza judet</option>
                                                    {counties.map((county) => <option value={county}>{county}</option>)}
                                                </Input>
                                                {/*<select className="form-select" value={formData.judet} name="judet" onChange={onChangeInput}>*/}
                                                {/*    <option value="" disabled selected hidden>Selecteaza judet</option>*/}
                                                {/*    {counties.map((county) => <option value={county}>{county}</option>)}*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Localitate*</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="localitate"
                                                    value={formData.localitate}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option value="" disabled selected hidden>Selecteaza localitate</option>
                                                    {towns.map((town) => <option value={town}>{town}</option>)}
                                                </Input>
                                                {/*<select className="form-select" value={formData.localitate} name="localitate" onChange={onChangeInput}>*/}
                                                {/*    <option value="" disabled selected hidden>Selecteaza localitate</option>*/}
                                                {/*    {towns.map((town) => <option value={town}>{town}</option>)}*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-adresa-input" className="col-md-3 col-form-label">Adresa(str, nr, bl, et)*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="adresa" value={formData.adresa} placeholder="Introdu adresa" id="example-adresa-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-0">
                                            <Label className="col-md-3 col-form-label">Mediu*</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="mediu"
                                                    value={formData.mediu}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option value="" disabled selected hidden>Selecteaza mediul</option>
                                                    <option value="Urban">Urban</option>
                                                    <option value="Rural">Rural</option>
                                                </Input>
                                                {/*<select className="form-select" value={formData.mediu} name="mediu" onChange={onChangeInput}>*/}
                                                {/*    <option value="" disabled selected hidden>Selecteaza mediul</option>*/}
                                                {/*    <option value="Urban">Urban</option>*/}
                                                {/*    <option value="Rural">Rural</option>*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>
                                        <br/>
                                        <h4 className="card-title"><b>Adresa resedinta</b></h4>
                                        <div className="form-check mb-3">
                                            <Input className="form-check-input" checked={formData.aceeasiCuCi === "da"} name="aceeasiCuCi" type="checkbox" id="defaultCheck1" onChange={onChangeInput} />
                                            <Label className="form-check-label" htmlFor="defaultCheck1">
                                                Aceeasi cu CI
                                            </Label>
                                        </div>
                                        {formData.aceeasiCuCi === "da" && (
                                            <>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-judetresedinta-input" className="col-md-3 col-form-label">Judet</Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="judetResedinta" value={formData.judet} readonly="readonly" id="example-judetresedinta-input" onChange={onChangeInput}/>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-localitateresedinta-input" className="col-md-3 col-form-label">Localitate</Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="localitateResedinta" value={formData.localitate} readonly="readonly" id="example-localitateresedinta-input" onChange={onChangeInput}/>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-adresaresedinta-input" className="col-md-3 col-form-label">Adresa</Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="adresaResedinta" value={formData.adresa} readonly="readonly" id="example-adresaresedinta-input" onChange={onChangeInput}/>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-mediuresedinta-input" className="col-md-3 col-form-label">Mediu</Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="mediuResedinta" value={formData.mediu} readonly="readonly" id="example-mediuresedinta-input" onChange={onChangeInput}/>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        {formData.aceeasiCuCi !== "da" && (
                                            <>
                                                <FormGroup row className="mb-3">
                                                    <Label className="col-md-3 col-form-label">Judet</Label>
                                                    <Col md={9}>
                                                        <Input
                                                            type="select"
                                                            name="judetResedinta"
                                                            value={formData.judetResedinta}
                                                            onChange={onChangeInput}
                                                            className="custom-select"
                                                        >
                                                            <option value="" disabled selected hidden>Selecteaza judet resedinta</option>
                                                            {counties.map((item) => <option value={item}>{item}</option>)}
                                                        </Input>
                                                        {/*<select className="form-select" value={formData.judetResedinta} name="judetResedinta" onChange={onChangeInput} >*/}
                                                        {/*    <option value="" disabled selected hidden>Selecteaza judet resedinta</option>*/}
                                                        {/*    {counties.map((item) => <option value={item}>{item}</option>)}*/}
                                                        {/*</select>*/}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mb-3">
                                                    <Label className="col-md-3 col-form-label">Localitate</Label>
                                                    <Col md={9}>
                                                        <Input
                                                            type="select"
                                                            name="localitateResedinta"
                                                            value={formData.localitateResedinta}
                                                            onChange={onChangeInput}
                                                            className="custom-select"
                                                        >
                                                            <option value="" disabled selected hidden>Selecteaza localitate resedinta</option>
                                                            {townsResedinta.map((item) => <option value={item}>{item}</option>)}
                                                        </Input>
                                                        {/*<select className="form-select" value={formData.localitateResedinta} name="localitateResedinta" onChange={onChangeInput}>*/}
                                                        {/*    <option value="" disabled selected hidden>Selecteaza localitate resedinta</option>*/}
                                                        {/*    {townsResedinta.map((item) => <option value={item}>{item}</option>)}*/}
                                                        {/*</select>*/}
                                                    </Col>
                                                </FormGroup>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-adresaresedinta-input" className="col-md-3 col-form-label">Adresa</Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="adresaResedinta" value={formData.adresaResedinta} placeholder="Introdu adresa resedinta" id="example-adresaresedinta-input" onChange={onChangeInput} />
                                                    </Col>
                                                </Row>
                                                <FormGroup row className="mb-0">
                                                    <Label className="col-md-3 col-form-label">Mediu</Label>
                                                    <Col md={9}>
                                                        <Input
                                                            type="select"
                                                            name="mediuResedinta"
                                                            value={formData.mediuResedinta}
                                                            onChange={onChangeInput}
                                                            className="custom-select"
                                                        >
                                                            <option value=""  disabled selected hidden>Selecteaza mediu resedinta</option>
                                                            <option value="Urban">Urban</option>
                                                            <option value="Rural">Rural</option>
                                                        </Input>
                                                        {/*<select className="form-select" name="mediuResedinta" value={formData.mediuResedinta} onChange={onChangeInput}>*/}
                                                        {/*    <option value=""  disabled selected hidden>Selecteaza mediu resedinta</option>*/}
                                                        {/*    <option value="Urban">Urban</option>*/}
                                                        {/*    <option value="Rural">Rural</option>*/}
                                                        {/*</select>*/}
                                                    </Col>
                                                </FormGroup>
                                            </>
                                        )}

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={6}>
                                <Card style={{height: "100%"}}>
                                    <CardBody>
                                        <h4 className="card-title"><b>Date contact</b></h4>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-email-input" className="col-md-3 col-form-label">Email*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="email" value={formData.email} placeholder="Introdu Email" id="example-email-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-telefon-input" className="col-md-3 col-form-label">Telefon*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="telefon" value={formData.telefon === "" ? "+40" : formData.telefon} placeholder="Introdu Telefon" id="example-telefon-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Partener*</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="partener"
                                                    value={formData.partener}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option value="" disabled selected hidden>Selecteaza partener</option>
                                                    <option value="Ward Ashby Studio SRL">Ward Ashby Studio SRL</option>
                                                    <option value="Grantbox SRL">Grantbox SRL</option>
                                                    <option value="Nedefinit">Nedefinit</option>
                                                </Input>
                                                {/*<select className="form-select" name="partener" value={formData.partener} onChange={onChangeInput}>*/}
                                                {/*    <option value="" disabled selected hidden>Selecteaza partener</option>*/}
                                                {/*    <option value="Ward Ashby Studio SRL">Ward Ashby Studio SRL</option>*/}
                                                {/*    <option value="Grantbox SRL">Grantbox SRL</option>*/}
                                                {/*    <option value="Nedefinit">Nedefinit</option>*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={6}>
                                <Card style={{height: "100%"}}>
                                    <CardBody>
                                        <h4 className="card-title"><b>Alte info</b></h4>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Tip somer*</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="tipSomer"
                                                    value={formData.tipSomer}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option defaultValue="Somer pe o perioada mai mare de 12 luni" >Somer pe o perioada mai mare de 12 luni ( pentru cei cu varsta cuprinsa intre 25- 29 de ani)</option>
                                                    <option value="Nu">Nu</option>
                                                    <option value="Somer pe o perioada de min 6 luni">Somer pe o perioada de min 6 luni(pentru cei cu varsta cuprinsa intre 16-24 de ani si pana la implinirea varstei de 25 de ani)</option>
                                                </Input>
                                                {/*<select className="form-select" name="tipSomer" value={formData.tipSomer} onChange={onChangeInput}>*/}
                                                {/*    <option defaultValue="Somer pe o perioada mai mare de 12 luni" >Somer pe o perioada mai mare de 12 luni ( pentru cei cu varsta cuprinsa intre 25- 29 de ani)</option>*/}
                                                {/*    <option value="Nu">Nu</option>*/}
                                                {/*    <option value="Somer pe o perioada de min 6 luni">Somer pe o perioada de min 6 luni(pentru cei cu varsta cuprinsa intre 16-24 de ani si pana la implinirea varstei de 25 de ani)</option>*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Reprezentare*</Label>
                                            <Col md={9}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="reprezentare" checked={formData.reprezentare === "da"} type="checkbox" value="" id="defaultCheck2" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck2">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-numereprezentare-input" className="col-md-3 col-form-label">Nume Reprezentare</Label>
                                            <Col md={9}>
                                                <Input type="text" className={formData.reprezentare === "da" && formData.numeReprezentare.length === 0 ? "is-invalid" : ""} name="numeReprezentare" value={formData.numeReprezentare} placeholder="Introdu nume reprezentare" id="example-numereprezentare-input" onChange={onChangeInput}  />
                                                <div className="invalid-feedback">
                                                    Nume reprezentare nu poate fi un camp gol.
                                                </div>
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Stare sanatate*</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="stareSanatate"
                                                    value={formData.stareSanatate}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option defaultValue="nu">Nu am restricţii medicale care mă împiedică să prestez muncă</option>
                                                    <option value="da">am restricţii medicale care mă împiedică să prestez o anumită muncă, după cum urmează **)</option>
                                                </Input>
                                                {/*<select className="form-select" name="stareSanatate"  value={formData.stareSanatate} onChange={onChangeInput}>*/}
                                                {/*    <option defaultValue="nu">Nu am restricţii medicale care mă împiedică să prestez muncă</option>*/}
                                                {/*    <option value="da">am restricţii medicale care mă împiedică să prestez o anumită muncă, după cum urmează **)</option>*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-detaliistaresanatate-input" className="col-md-3 col-form-label">Detalii stare sanatate</Label>
                                            <Col md={9}>
                                                <Input type="text" name="detaliiStareSanatate" value={formData.detaliiStareSanatate} placeholder="Introdu detalii stare sanatate" id="example-detaliistaresanatate-input" onChange={onChangeInput}  />
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Etnie roma</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="etnieRoma"
                                                    value={formData.etnieRoma}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option disabled selected hidden>Selecteaza etnie</option>
                                                    <option value="Da">Da</option>
                                                    <option value="Nu">Nu</option>
                                                </Input>
                                                {/*<select className="form-select" name="etnieRoma" value={formData.etnieRoma} onChange={onChangeInput}>*/}
                                                {/*    <option disabled selected hidden>Selecteaza etnie</option>*/}
                                                {/*    <option value="Da">Da</option>*/}
                                                {/*    <option value="Nu">Nu</option>*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={6}>
                                <Card style={{height: "100%"}}>
                                    <CardBody>
                                        <h4 className="card-title" style={{backgroundColor: "black", color: "white", maxWidth: "270px"}}><b>Alte date GT Sectiunea A</b></h4>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Categorie GT</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="categorieGt"
                                                    value={formData.categorieGt}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option value="" disabled selected hidden>Selecteaza categorie</option>
                                                    <option value="Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma">Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma</option>
                                                    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, înregistrați la Serviciul Public de Ocupare și cu rezidența în regiunile eligibile, cu accent pe cei din mediul rural și cei aparținând minorității roma">Tineri NEETs șomeri, cu vârsta între 16-29 ani, înregistrați la Serviciul Public de Ocupare și cu rezidența în regiunile eligibile, cu accent pe cei din mediul rural și cei aparținând minorității roma</option>
                                                    <option value="Tineri NEETs inactivi, cu vârsta între 16-29 ani, neînregistrați la SPO, cu accent pe aceia cu nivel scăzut de competențe și care au dificultăți în a se integra social">Tineri NEETs inactivi, cu vârsta între 16-29 ani, neînregistrați la SPO, cu accent pe aceia cu nivel scăzut de competențe și care au dificultăți în a se integra social</option>
                                                    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității rome">Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității rome</option>
                                                    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome.">Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome.</option>
                                                    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome, inclusiv tineri șomer(...)">Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome, inclusiv tineri șomer(...)</option>
                                                    <option value="Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma">Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma</option>

                                                </Input>
                                                {/*<select className="form-select" name="categorieGt" value={formData.categorieGt} onChange={onChangeInput}>*/}
                                                {/*    <option value="" disabled selected hidden>Selecteaza categorie</option>*/}
                                                {/*    <option value="Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma">Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma</option>*/}
                                                {/*    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, înregistrați la Serviciul Public de Ocupare și cu rezidența în regiunile eligibile, cu accent pe cei din mediul rural și cei aparținând minorității roma">Tineri NEETs șomeri, cu vârsta între 16-29 ani, înregistrați la Serviciul Public de Ocupare și cu rezidența în regiunile eligibile, cu accent pe cei din mediul rural și cei aparținând minorității roma</option>*/}
                                                {/*    <option value="Tineri NEETs inactivi, cu vârsta între 16-29 ani, neînregistrați la SPO, cu accent pe aceia cu nivel scăzut de competențe și care au dificultăți în a se integra social">Tineri NEETs inactivi, cu vârsta între 16-29 ani, neînregistrați la SPO, cu accent pe aceia cu nivel scăzut de competențe și care au dificultăți în a se integra social</option>*/}
                                                {/*    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității rome">Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității rome</option>*/}
                                                {/*    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome.">Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome.</option>*/}
                                                {/*    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome, inclusiv tineri șomer(...)">Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome, inclusiv tineri șomer(...)</option>*/}
                                                {/*    <option value="Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma">Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma</option>*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Situatia pe piata FM</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="situatiePePiataFm"
                                                    value={formData.situatiePePiataFm}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option value="" disabled selected hidden>Selecteaza categorie</option>
                                                    <option value="1">Angajat</option>
                                                    <option value="2">Somer</option>
                                                    <option value="3">Angajat pe cont propriu</option>
                                                    <option value="4">Somer de lunga durata</option>
                                                    <option value="5">Persoana inactiva inclusiv copii anteprescolari, prescolari, elevi, etc</option>
                                                    <option value="6">Alta categorie de inactivi inafara de cei din educatie si formare</option>
                                                </Input>
                                                {/*<select className="form-select" name="situatiePePiataFm"  value={formData.situatiePePiataFm} onChange={onChangeInput}>*/}
                                                {/*    <option value="" disabled selected hidden>Selecteaza categorie</option>*/}
                                                {/*    <option value="1">Angajat</option>*/}
                                                {/*    <option value="2">Somer</option>*/}
                                                {/*    <option value="3">Angajat pe cont propriu</option>*/}
                                                {/*    <option value="4">Somer de lunga durata</option>*/}
                                                {/*    <option value="5">Persoana inactiva inclusiv copii anteprescolari, prescolari, elevi, etc</option>*/}
                                                {/*    <option value="6">Alta categorie de inactivi inafara de cei din educatie si formare</option>*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label className="col-md-10 col-form-label">Inscris intr-un program de educatie</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="inscrisInEducatie" checked={formData.inscrisInEducatie === "da"} type="checkbox" value="" id="defaultCheck3" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck3">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label className="col-md-10 col-form-label">Inscris intr-un program de formare</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="inscrisInFormare" checked={formData.inscrisInFormare === "da"} type="checkbox" value="" id="defaultCheck4" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck4">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Nivel educatie</Label>
                                            <Col md={9}>
                                                <Input
                                                    type="select"
                                                    name="nivelEducatie"
                                                    value={formData.nivelEducatie}
                                                    onChange={onChangeInput}
                                                    className="custom-select"
                                                >
                                                    <option value="" disabled selected hidden>Selecteaza nivel educatie</option>
                                                    <option value="0">Studii educatie timpurie (ISCED 0)</option>
                                                    <option value="1">Studii primare (ISCED 1)</option>
                                                    <option value="2">Studii gimnaziale (ISCED 2)</option>
                                                    <option value="3">Studii liceale (ISCED 3)</option>
                                                    <option value="4">Studii postliceale (ISCED 4)</option>
                                                    <option value="5">Studii superioare (ISCED 5)</option>
                                                    <option value="6">Studii superioare (ISCED 6)</option>
                                                    <option value="7">Studii superioare (ISCED 7)</option>
                                                    <option value="8">Studii superioare (ISCED 8)</option>
                                                    <option value="9">fara ISCED</option>
                                                </Input>
                                                {/*<select className="form-select" name="nivelEducatie"  value={formData.nivelEducatie} onChange={onChangeInput}>*/}
                                                {/*    <option value="" disabled selected hidden>Selecteaza nivel educatie</option>*/}
                                                {/*    <option value="0">Studii educatie timpurie (ISCED 0)</option>*/}
                                                {/*    <option value="1">Studii primare (ISCED 1)</option>*/}
                                                {/*    <option value="2">Studii gimnaziale (ISCED 2)</option>*/}
                                                {/*    <option value="3">Studii liceale (ISCED 3)</option>*/}
                                                {/*    <option value="4">Studii postliceale (ISCED 4)</option>*/}
                                                {/*    <option value="5">Studii superioare (ISCED 5)</option>*/}
                                                {/*    <option value="6">Studii superioare (ISCED 6)</option>*/}
                                                {/*    <option value="7">Studii superioare (ISCED 7)</option>*/}
                                                {/*    <option value="8">Studii superioare (ISCED 8)</option>*/}
                                                {/*    <option value="9">fara ISCED</option>*/}
                                                {/*</select>*/}
                                            </Col>
                                        </FormGroup>

                                        <Row className="mb-3">
                                            <Label className="col-md-10 col-form-label">Persoana dezavantajata</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="persoanaDezavantajata" checked={formData.persoanaDezavantajata === "da"} type="checkbox" value="" id="defaultCheck4" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck4">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <h4 className="card-title"><b>Grupuri vulnerabile</b></h4>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Participanti care traiesc in gospodarii fara persoane ocupate</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="participantiFaraPersoaneOcupate" checked={formData.participantiFaraPersoaneOcupate === "da"} type="checkbox" value="" id="defaultCheck4" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck4">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Participanti care traiesc in gospodarii fara persoane ocupate cu copii aflati in intretinere</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="participantiCuCopiiInIntretinere" checked={formData.participantiCuCopiiInIntretinere === "da"} type="checkbox" value="" id="defaultCheck5" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck5">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Participantii care traiesc in gospodarii alcatuite dintr-un parinte unic cu copii aflati in intretinere</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="participantiParinteUnic"  checked={formData.participantiParinteUnic === "da"} type="checkbox" value="" id="defaultCheck6" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck6">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Migranti</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="migranti" type="checkbox" checked={formData.migranti === "da"} value="" id="defaultCheck7" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck7">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Participanti de origine straina</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="participantiOrgineStraina" checked={formData.participantiOrgineStraina === "da"} type="checkbox" value="" id="defaultCheck8" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck8">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Minoritati de etnie Roma</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="minoritatiEtnieRoma" checked={formData.minoritatiEtnieRoma === "da"} type="checkbox" value="" id="defaultCheck9" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck9">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Alte minoritati decat cele de etnie Roma</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="alteMinoritati" checked={formData.alteMinoritati === "da"} type="checkbox" value="" id="defaultCheck10" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck10">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Persoane cu dizabilitati</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="persoaneCuDizabilitati"  checked={formData.persoaneCuDizabilitati === "da"} type="checkbox" value="" id="defaultCheck11" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck11">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Alte categorii de persoane dezavantajate</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="alteCategorii" type="checkbox"  checked={formData.alteCategorii === "da"} value="" id="defaultCheck12" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck12">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Persoane fara adapost sau care sunt afectate de excluziunea locativa</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="persoaneFaraAdapost"  checked={formData.persoaneFaraAdapost === "da"} type="checkbox" value="" id="defaultCheck13" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck13">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Apartin la comunitati marginalizate</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="apartinComunitatiMarginalizate"  checked={formData.apartinComunitatiMarginalizate === "da"} type="checkbox" value="" id="defaultCheck14" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck14">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={7}></Col>
                            <Col xs={2} align="center">
                            </Col>
                            <Col xs={2} align="center">
                                <Button
                                    color="dark"
                                    className="waves-effect waves-light me-1"
                                    onClick={handleSubmit}
                                >
                                    Inscriere
                                </Button>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                    </form>
                    <br/>
                    <br/>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FormularInscriere;
