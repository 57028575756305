import React, {useEffect} from 'react';
import StickyMenu from "../../lib/StickyMenu";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import FileDownload from "js-file-download";
import axiosInstance from "../Helper/AxiosHelper";

const ListaCastigatori = () => {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);

    function downloadFile(e) {

        e.preventDefault();
        let fileName = "";
        if (e.target.id === 'doc1') {
            fileName = "Lista_intermediara_planuri afaceri_9.12.2022.docx";
        }
        else if (e.target.id === 'doc2') {
            fileName = "Lista_finala_planuri afaceri_14.12.2022.pdf";
        }
        else {
            fileName = e.target.innerText;
        }
        axiosInstance.get(`/api/sv/files/downloads/${fileName}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(res)
            FileDownload(res.data, `${fileName}`)
        })
    }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <section className="appie-blog-3-area pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-blog-item-3 mt-30">
                                <div className="col-lg-12">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                        </div>
                                        <div className="col-lg-8">
                                            {/*<h4 className="appie-title">Listă centralizatoare finală evaluare eligibilitate și conformitate administrativă</h4>*/}
                                            <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                                Lista intermediara planuri afaceri - 09.12.2022
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="content" >
                                                <button className="main-btn" id="doc1" onClick={downloadFile}>
                                                    <i className="fal fa-download" />Descarca
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="appie-blog-3-area pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-blog-item-3 mt-30">
                                <div className="col-lg-12">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                        </div>
                                        <div className="col-lg-8">
                                            {/*<h4 className="appie-title">Listă centralizatoare finală evaluare eligibilitate și conformitate administrativă</h4>*/}
                                            <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                                Lista finala planuri afaceri - 14.12.2022
                                            </p>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="content" >
                                                <button className="main-btn" id="doc2" onClick={downloadFile}>
                                                    <i className="fal fa-download" />Descarca
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    );
};

export default ListaCastigatori;
