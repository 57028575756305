import React, {useEffect, useState} from 'react';
import {useAtom} from "jotai";
import useToggle from "../../Hooks/useToggle";
import axiosInstance from "../Helper/AxiosHelper";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import Cursuri from "./Cursuri";
import CursuriSideBar from "./CursuriSideBar";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import {userAtom} from "../Login/PaginaLogin";

const PaginaCursuri = () => {
    const [user] = useAtom(userAtom);
    const [drawer, drawerAction] = useToggle(false);
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/sv/material/all').then((response) => {
            setCourses(response.data);
        })
    }, []);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-7">
                            <Cursuri courses={courses}/>
                        </div>
                        <div className="col-lg-4 col-md-5">
                            <CursuriSideBar courses={courses} setCourses={setCourses}/>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaCursuri;
