import React from 'react';

const MediereaMuncii = ({ className }) => {
    return (
        <>
            <section className={`appie-download-3-area pt-100 ${className || ''}`} id="download" style={{paddingBottom: '100px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Medierea Muncii</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-download-3-box wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="content">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                                Pe intreaga perioada a derularii proiectului, se vor furniza grupului tinta servicii de medierea muncii,
                                                organizate si desfasurate in conformitate cu prevederile Legii nr. 76/2002 privind sistemul
                                                asigurarilor pentru somaj si stimularea ocuparii fortei de munca, cu modificarile si completarile
                                                ulterioare.
                                            </p>
                                            <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                                Pe langa aceasta activitate, se vor organiza 3 burse ale locurilor de munca, pentru a
                                                facilita angajarea persoanelor din grupul tinta.
                                            </p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                                Bursele locurilor de munca se vor desfasura in regiunea de implementare si vor avea invitati atat membri ai grupului tinta cat si reprezentanti ai
                                                mediului de afaceri care sunt in cautarea de angajati.
                                            </p>
                                            <p align="justify" style={{fontSize: '20px', color: 'black'}}>
                                                Angajatorii care angajeaza persoane din grupul tinta se pot adresa structurilor teritoriale ale ANOFM
                                                pentru a beneficia de subventiile acordate in baza OUG nr. 60 din 5 iulie 2018 pentru modificarea si
                                                completarea unor acte normative din domeniul fortei de munca.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};

export default MediereaMuncii;
