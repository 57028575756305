import React from 'react';

const GrupTinta = ({ className }) => {
    return (
        <>
            <section className={`appie-services-2-area pt-100 pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Grup Tinta</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    376 de tineri NEETs nivel de ocupabilitate A, B, C si D cu varsta intre 16 si 29 de ani
                                    inregistrati si profilati la SPO (min 20% din mediul rural si minim 10% din randul minoritatii
                                    roma).
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    376 de tineri NEETs vor beneficia de servicii de medierea muncii.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    320 de persoane vor urma cursuri de calificare autorizate ANC de nivel 2 - 360 ore in
                                    domeniile identificate de catre Solicitant, ei facand parte din categoriile B,C,D.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    56 vor urma si cursuri de specializare/perfectionare de competente antreprenoriale, in
                                    sistem formal, ei facand parte din categoria A.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    15 persoane vor beneficia de subventii pentru infiintarea unei afaceri, in valoare de 120.000
                                    lei (infiintarea a minim un loc de munca).
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" style={{display: 'flex', minHeight: '60px'}}>
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-users" />
                                </div>
                                <br/>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    162 de participant se vor angaja ca urmare a primirii unei oferte de munca in urma
                                    campaniilor de informare si a participatii la bursa locurilor de munca.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GrupTinta;
