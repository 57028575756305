import React, {useEffect, useState} from 'react';
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import FormularInscriere from "../Formular Inscriere/FormularInscriere";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import GrupTinta from "./GrupTinta";
import axiosInstance from "../Helper/AxiosHelper";
import {useAtom} from "jotai";
import {userAtom} from "../Login/PaginaLogin";

const PaginaGrupTinta = () => {
    const [drawer, drawerAction] = useToggle(false);
    const [userFromAtom, setUserFromAtom] = useAtom(userAtom);


    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-7">
                            <GrupTinta />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaGrupTinta;
