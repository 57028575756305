import React from 'react';
import {Navigate} from "react-router-dom";
import PaginaIncarcaPlanDeAfaceri from "./IncarcarePlanDeAfaceri/PaginaIncarcaPlanDeAfaceri";
import PaginaDocumenteAdmin from "./DocumenteIncarcateDeAntreprenori/PaginaDocumenteAdmin";
import PaginaGrupTinta from "./GrupTinta/PaginaGrupTinta";

const ProtectedRouteAdmin = () => {
    if (localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro') {
        if (window.location.pathname === "/documente-admin") return <PaginaDocumenteAdmin />;
        else if (window.location.pathname === "/grup-tinta") return <PaginaGrupTinta />
    }
    return <Navigate to="/" />
};

export default ProtectedRouteAdmin;
