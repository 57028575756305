import React, {useEffect, useState} from 'react';
import StickyMenu from "../../lib/StickyMenu";
import useToggle from "../../Hooks/useToggle";
import {useLocation} from "react-router-dom";
import axios from "axios";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import Curs from "./Curs";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import axiosInstance from "../Helper/AxiosHelper";

const PaginaCurs = () => {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    const [course, setCourse] = useState();
    const [isLoading, setLoading] = useState(true);
    const location = useLocation();
    const idParam = location.search.substring(4)
    const client = axios.create({
        baseURL: "https://neets-mnt-backend.azurewebsites.net/api/sv/material"
        // baseURL: "http://localhost:8080/api/material"
    });

    useEffect(() => {
        async function fetchData() {
            // await client.get('/id', {
            //     params: {
            //         id: idParam
            //     },
            //     // responseType:"blob"
            // }).then(r => {
            //     setCourse(r.data);
            //     setLoading(false);
            // })

            await axiosInstance.get('/api/sv/material/id', {
                params: {
                    id: idParam
                },
            }).then(r => {
                setCourse(r.data);
                setLoading(false);
            })
        }
        fetchData()

    }, []);

    if (isLoading) {
        return <div></div>;
    }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <Curs course={course}/>
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaCurs;
