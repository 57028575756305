import React, {useEffect, useState} from 'react';
import StickyMenu from "../../lib/StickyMenu";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import SectiuniDocumente from "./SectiuniDocumente";
import axiosInstance from "../Helper/AxiosHelper";

const PaginaDocumenteAdmin = () => {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    // const [users, setUsers] = useState([]);
    //
    // useEffect(() => {
    //     axiosInstance.get('/api/sv/user/all').then((response) => {
    //         setUsers(response.data);
    //         console.log(response.data)
    //     })
    // }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <SectiuniDocumente />
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaDocumenteAdmin;
