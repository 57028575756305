import React from 'react';
import blogImg1 from "../../assets/images/blog/1.jpg";
import {Link} from "react-router-dom";

const Cursuri = ({courses}) => {

    return (
        <>
            <div id="articles" className="row">
                {courses.map((course) => {
                    return (
                        <>
                            {course.isPublic && localStorage.getItem('user') !== 'cristina.udateanu@goodstory.ro' && (
                                <div className="col-lg-6" key={course.id}>
                                    <div className="post-item-1" >
                                        {course.data && (
                                            <img src={`data:${course.type};base64,${course.data}`} alt="" style={{height: '300px'}}/>
                                        )}
                                        {!course.data && (
                                            <img src={blogImg1} alt="" style={{height: '300px'}}/>
                                        )}

                                        <div className="b-post-details">
                                            <h3>
                                                {/*<a href={"/material?id=" + course.id}>*/}
                                                {/*    {course.title}*/}
                                                {/*</a>*/}
                                                <Link to={"/material?id=" + course.id}>
                                                    {course.title}
                                                </Link>
                                            </h3>
                                            <a className="read-more" href={"/material?id=" + course.id}>
                                                Vezi detalii<i className="fal fa-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                <div className="col-lg-6" key={course.id}>
                                    <div className="post-item-1" >
                                        {course.data && (
                                            <img src={`data:${course.type};base64,${course.data}`} alt="" style={{height: '300px'}}/>
                                        )}
                                        {!course.data && (
                                            <img src={blogImg1} alt="" style={{height: '300px'}}/>
                                        )}

                                        <div className="b-post-details">
                                            <h3>
                                                <Link to={"/material?id=" + course.id}>
                                                    {course.title}
                                                </Link>
                                            </h3>
                                            <a className="read-more" href={"/material?id=" + course.id}>
                                                Vezi detalii<i className="fal fa-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </>

                    )
                })}
            </div>
            {/*{courses.length > 0 && (*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-lg-12">*/}
            {/*            <div className="bisylms-pagination">*/}
            {/*                <span className="current">01</span>*/}
            {/*                <a href="src/components/Articole/Blogs#Articole.js">02</a>*/}
            {/*                <a className="next" href="src/components/Articole/Blogs#Articole.js">*/}
            {/*                    next<i className="fal fa-arrow-right"></i>*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
        </>
    );
};

export default Cursuri;
