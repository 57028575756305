import React, {useEffect} from 'react';
import StickyMenu from "../../lib/StickyMenu";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import ListaMonitorizareProiect from "./ListaMonitorizareProiect";

const PaginaMonitorizareProiect = () => {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <div className="row" style={{paddingTop: '50px', paddingBottom: '100px'}}>
                <div className="col-lg-12">
                    <ListaMonitorizareProiect />
                </div>
            </div>
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaMonitorizareProiect;
