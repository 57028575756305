import React from 'react';

const FormareAntreprenoriala = () => {
    return (
        <section className="appie-blog-3-area pt-90 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Formare Antreprenoriala</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-blog-item-3 mt-30">
                            <div className="content">
                                <div className="row align-items-center">
                                    <p align="justify" style={{fontSize: '20px'}}>
                                        Aceasta activitate este destinata grupului tinta apartinand categoriei A NEETS, care sunt eligibili
                                            pentru participarea la cursuri de formare antreprenoriala, mentorat si consultanta in realizarea unui
                                            plan de afaceri si participarea la concursul de planuri de afaceri in urma caruia 56 dintre tinerii
                                            NEETS apartinand categoriei A vor participa la cursurile de formare antreprenoriala si 15 dintre
                                            participanti vor primi o subventie de 120.000 lei.<br/>
                                    </p>
                                    <br/><br/><br/><br/><br/>
                                    <p align="justify" style={{fontSize: '24px'}}>
                                        <b>Cum se desfasoara cursul?</b>
                                    </p>
                                    <br/>
                                    <p align="justify" style={{fontSize: '20px'}}>
                                        In functie de termenii de referinta stabiliti in cadrul pregatirii formarii, se va derula efectiv programul
                                        de formare antreprenoriala autorizat de Autoritatea Nationala pentru Calificari. Cursul va avea 90
                                        ore. Repartizarea acestor ore va viza atat o componenta teoretica, cat si o componenta practica.
                                    </p>
                                    <br/><br/><br/>
                                    <p align="justify" style={{fontSize: '20px'}}>
                                        Scopul planificarii si utilitatea planului de afaceri:
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <div className="appie-how-it-work-content">
                                        <div className="row" >
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-globe" /></span>
                                                    <p align="justify">
                                                        Planificarea afacerii asigura cadrul general de stabilire a coordonatelor generale ale afacerii:
                                                        produsele si serviciile, clientii, piata tinta, avantajele concurentiale, „viteza de dezvoltare” a afacerii.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="how-it-work-box" >
                                                    <span><i className="fal fa-globe" /></span>
                                                    <p align="justify">
                                                        Planul de afaceri antreprenorial reprezinta instrumentul cu ajutorul caruia intreprinzatorul isi
                                                        clarifica toate atuurile si riscurile afacerii si gaseste solutii pentru alocarea si administrarea eficienta
                                                        a resurselor firmei, astfel incat sa asigure dezvoltarea durabila si profitabila a afacerii.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <p align="justify" style={{fontSize: '20px'}}>
                                        <br/>
                                        <br/>
                                        Vor avea loc intalniri preliminare cu membrii grupului tinta pentru evaluarea viabilitatii ideilor de
                                        afaceri (vor fi discutate inclusiv ideile de afaceri propuse de participanti in cadrul temei practice a
                                        cursului de competente antreprenoriale), se va stabili de comun acord agenda intalnirilor de lucru .
                                    </p>
                                    <p align="justify" style={{fontSize: '20px'}}>
                                        <br/>
                                        Se va organiza un concurs de planuri de afaceri pentru a selecta cele mai bune 15 planuri.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FormareAntreprenoriala;
