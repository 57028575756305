import React from 'react';
import {useAtom} from "jotai";
import {userAtom} from "../Login/PaginaLogin";
import {useEffect, useState} from "react";
import axiosInstance from "../Helper/AxiosHelper";
import FileDownload from "js-file-download";
import {FaFileUpload} from "react-icons/fa";
import BorderSpinner from "../../lib/BorderSpinner";
import {GiPlainCircle} from "react-icons/gi";

const ListaCvuriAngajare = () => {
    const [atomId, setAtomId] = useAtom(userAtom);
    const [uploads, setUploads] = useState([]);
    const [isShowingSpinner, setIsShowingSpinner] = useState(false);

    useEffect(() => {
        getFiles();
    }, []);

    async function downloadFile(event, upload) {
        event.preventDefault();
        axiosInstance.get(`/api/sv/cvuri/download/${upload.id}`, {
            responseType: "blob"
        }).then((res) => {
            const response = res.data;
            FileDownload(response, upload.fileName, response.type)
        })
    }

    function getFiles() {
        axiosInstance.get('/api/sv/cvuri/all')
            .then((response) => setUploads(response.data));
    }

    async function uploadFile(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        try {
            await axiosInstance.post('/api/sv/cvuri/add', formData, {
                headers: {"Content-Type": "multipart/form-data"},
            }).then(res => {
                if (!(res.status === 200 || res.status === 201)) {
                    throw new Error(`Error! status: ${res.status}`);
                }
            }).catch((error) => console.log(error))
        } catch (error) {
            console.log(error)
        }
        getFiles();
    }

    async function changeStatus(event, upload) {
        event.preventDefault();
        setIsShowingSpinner(true);
        try {
            await axiosInstance.put('/api/sv/cvuri/update', upload, {
                params : {
                    id: upload.id
                }
            }).then(res => {
                if (!(res.status === 200 || res.status === 201)) {
                    throw new Error(`Error! status: ${res.status}`);
                }
                setIsShowingSpinner(false);
            }).catch((error) => console.log(error))
        } catch (error) {
            setIsShowingSpinner(false);
            console.log(error)
        }
        setIsShowingSpinner(false);
        getFiles();
    }

    return (
        <>
            <div className="container" style={{  display: "flex", justifyContent: "center", alignItems: "center"}}>
                {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                    <label htmlFor="filePicker1" style={{cursor:"pointer"}}>
                        <div className="blog-sidebar">
                            <aside className="widget widget-trend-post" >
                                <h3 align="center"><FaFileUpload /></h3>
                                <h3>Incarca document</h3>
                            </aside>
                        </div>
                        <input id="filePicker1" type="file" name="file1" onChange={uploadFile} style={{display: "none"}}></input>
                    </label>
                )}
            </div>

            {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && uploads.map((upload) => {
                return (
                    <section className="appie-blog-3-area pt-10" key={upload.id}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="appie-blog-item-3 mt-30">
                                        <div className="col-lg-12">
                                            <div className="row align-items-center">
                                                <div className="col-lg-2">
                                                    <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                                </div>
                                                <div className="col-lg-8">
                                                    <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                                        {upload.fileName}
                                                    </p>
                                                </div>
                                                <div className="col-lg-2" style={{ position: "relative" }}>
                                                    <div className="content">
                                                        <button className="main-btn" id="doc1" onClick={(event) => downloadFile(event, upload)}>
                                                            <i className="fal fa-download" />Descarca
                                                        </button>
                                                        {isShowingSpinner
                                                            ? <p style={{ position: "absolute", top: "-30px", right: "-20px"}}><BorderSpinner/></p>
                                                            : upload.isPublic
                                                                ? <p onClick={(event) => changeStatus(event, upload)} style={{ color: 'red', position: "absolute", top: "-30px", right: "-20px", cursor: "pointer" }}><GiPlainCircle />Ascunde</p>
                                                                : <p onClick={(event) => changeStatus(event, upload)} style={{ color: 'green', position: "absolute", top: "-30px", right: "-20px", cursor: "pointer" }}><GiPlainCircle />Publica</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            })}
            {localStorage.getItem('user') !== 'cristina.udateanu@goodstory.ro' && uploads.map((upload) => {
                if (upload.isPublic) {
                    return (
                        <section className="appie-blog-3-area pt-10" key={upload.id}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="appie-blog-item-3 mt-30">
                                            <div className="col-lg-12">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-2">
                                                        <h1 className="appie-title" style={{color: '#2b70fa'}}><i className="fal fa-file-pdf" /></h1>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <p align="center" style={{fontSize: '20px', color: '#2b70fa'}}>
                                                            {upload.fileName}
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-2" style={{ position: "relative" }}>
                                                        <div className="content">
                                                            <button className="main-btn" id="doc1" onClick={(event) => downloadFile(event, upload)}>
                                                                <i className="fal fa-download" />Descarca
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }

            })}
        </>
    );
};

export default ListaCvuriAngajare;
