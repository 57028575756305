import React, {useEffect, useState} from 'react';
import StickyMenu from "../../lib/StickyMenu";
import useToggle from "../../Hooks/useToggle";
import {useLocation} from "react-router-dom";
import axiosInstance from "../Helper/AxiosHelper";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";
import Job from "./Job";
import HeroArticol from "../Articole/HeroArticol";

const PaginaJobForEdit = () => {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <Job />
            <Footer />
            <BackToTop />
        </>
    );
};

export default PaginaJobForEdit;
