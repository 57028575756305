import React from 'react';
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Header from "../Home/Header";
import HeroArticol from "../Articole/HeroArticol";
import Forms from "./Forms";
import Footer from "../Home/Footer";
import BackToTop from "../BackToTop";

const PaginaContact = () => {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroArticol />
            <Forms />
            <Footer />
            <BackToTop />
        </>
    )
};

export default PaginaContact;
