import React, {useState} from 'react';
import axios from "axios";
import {GiPlainCircle} from "react-icons/gi";
import thumb from '../../assets/images/service-details-thumb.jpg';
import Quill from "../../lib/Quill";
import blogImg1 from "../../assets/images/blog/1.jpg";
import axiosInstance from "../Helper/AxiosHelper";

const Curs = ({course}) => {
    const [isShown, setIsShown] = useState(false);
    const [title, setTitle] = useState(course.title);
    const [text, setText] = useState(course.text);
    const [isPublic, setIsPublic] = useState(course.isPublic);
    const [materialType, setMaterialType] = useState(course.materialType);
    const [format, setFormat] = useState(course.format);
    const [image, setImage] = useState(`data:${course.type};base64,${course.data}`);
    const [imageToSend, setImageToSend] = useState();
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const [isMaterialTypeEditable, setIsMaterialTypeEditable] = useState(false);
    const [isFormatEditable,setIsFormatEditable] = useState(false);

    const showEditor = (e) => {

        if (localStorage.getItem('user') !== null) {
            if (isShown) {
                updateText(e).then(r => console.log(r));
            }
            setIsShown(!isShown);
        }
    };
    const uploadImage = (event) => {
        setImage(URL.createObjectURL(event.target.files[0]));
        setImageToSend(event.target.files[0]);
    }

    const updateText = async(e) => {
        e.preventDefault();
        setIsTitleEditable(false);
        setIsFormatEditable(false);
        setIsMaterialTypeEditable(false);
        const stringHtml = document.getElementById('tryEdit').innerHTML.toString();
        const courseJson = JSON.stringify({
            title : title,
            text : stringHtml,
            isPublic : isPublic,
            format : format,
            materialType : materialType,
        })
        try {
            const response = await axiosInstance.patch("/api/sv/material/update", courseJson, {
                headers: { "Content-Type": "application/json" },
                params: {
                    id: course.id
                },
            });
            // const response = await axios({
            //     method: "PATCH",
            //     url: "https://neets-mnt-backend.azurewebsites.net/api/sv/material/update",
            //     // url: "http://localhost:8080/api/material/update",
            //     data: courseJson,
            //     headers: { "Content-Type": "application/json" },
            //     params: {
            //         id: course.id
            //     },
            // });
            const data1 = await response;

            // await axiosInstance.patch('/api/course/update', {
            //
            //     // headers: {"Content-Type": "application/json"},
            //     params: {
            //         id: course.id,
            //         data: courseJson,
            //     },
            // })
        } catch(error) {
            console.log(error)
        }
    }

    const handleSubmit = async(event) => {
        // setIsShown(true);
        // console.log(selectedFile);
        event.preventDefault()
        const formData = new FormData();
        // selectedFile.forEach(file => {
        formData.append("image", imageToSend);
        formData.append("id", course.id);
        // })
        try {
            await axiosInstance.patch("/api/sv/material/update/image", formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType:"blob",
            });
            // const response = await axios({
            //     method: "patch",
            //     url: "https://neets-mnt-backend.azurewebsites.net/api/sv/material/update/image",
            //     // url: "http://localhost:8080/api/material/update/image",
            //     data: formData,
            //     headers: { "Content-Type": "multipart/form-data" },
            //     responseType:"blob",
            // });
            // setImage(`data:${response.data.type};base64,${response.data.data}`);
        } catch(error) {
            console.log(error)
        }
    }

    function handleTitleChange(e) {
        setTitle(e.target.value);
    }

    function selectMaterialStatus() {
        setIsPublic(!isPublic);
    }


    function handleMaterialTypeChange(e) {
        setMaterialType(e.target.value)
    }

    function handleFormatChange(e) {
        setFormat(e.target.value)
    }

    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                    <div className="service-category-widget" style={{textAlign: 'center'}}>
                                        <form onSubmit={updateText}>
                                            <label htmlFor="filePicker3" style={{cursor:"pointer"}}>
                                                <div onClick={selectMaterialStatus} style={{textAlign: 'center'}} >
                                                    {isPublic
                                                        ? <h3 style={{ color: 'red'}}><GiPlainCircle /> Ascunde Material</h3>
                                                        : <h3 style={{ color: 'green'}}><GiPlainCircle /> Publica Material</h3>
                                                    }
                                                </div>
                                                <input id="filePicker3" type="submit" name="file3" style={{display: "none"}}></input>
                                            </label>
                                        </form>
                                    </div>
                                )}
                                <div className="service-category-widget">
                                    {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                        <form onSubmit={updateText}>
                                            <ul>
                                                {isMaterialTypeEditable && (
                                                    <li>
                                                        <label htmlFor="filePicker1">
                                                            <i className="fal fa-tag"></i>
                                                            <input id="filePicker1" type="text" placeholder={materialType} name="file1" style={{maxWidth: '190px'}} onChange={handleMaterialTypeChange}>
                                                            </input>
                                                            <button type="submit" >
                                                                <i className="fal fa-upload" />
                                                            </button>
                                                        </label>
                                                    </li>
                                                )}
                                                {!isMaterialTypeEditable && (
                                                    <li onClick={() => setIsMaterialTypeEditable(!isMaterialTypeEditable)}>
                                                        <i className="fal fa-tag"></i>{materialType}
                                                    </li>
                                                )}
                                                {isFormatEditable && (
                                                    <li>
                                                        <label htmlFor="filePicker2">
                                                            <i className="fal fa-bookmark"></i>
                                                            <input id="filePicker2" type="text" placeholder={format} name="file2" style={{maxWidth: '190px'}} onChange={handleFormatChange}>
                                                            </input>
                                                            <button type="submit" >
                                                                <i className="fal fa-upload"></i>
                                                            </button>
                                                        </label>
                                                    </li>
                                                )}
                                                {!isFormatEditable && (
                                                    <li onClick={() => setIsFormatEditable(!isFormatEditable)}>
                                                        <i className="fal fa-bookmark"></i>{format}
                                                    </li>
                                                )}

                                            </ul>
                                        </form>
                                    )}
                                    {localStorage.getItem('user') !== 'cristina.udateanu@goodstory.ro' && (
                                        <ul>
                                            <li>
                                                <i className="fal fa-tag"></i>{course.materialType}
                                            </li>
                                            <li>
                                                <i className="fal fa-bookmark"></i>{course.format}
                                            </li>
                                        </ul>
                                    )}
                                </div>

                                {localStorage.getItem('user') === 'cristina.udateanu@goodstory.ro' && (
                                    <div className="service-category-widget">
                                        <form onSubmit={handleSubmit}>
                                            <ul>
                                                <li>
                                                    <label htmlFor="imagePicker"  style={{cursor:"pointer"}}>
                                                        <i className="fal fa-upload"></i>Incarca imagine
                                                        <input id="imagePicker" type="file" name="file" onChange={uploadImage} style={{display: "none"}}></input>
                                                        <button type="submit" >
                                                            Save
                                                        </button>
                                                    </label>
                                                </li>
                                                {/*<li>*/}
                                                {/*    <label htmlFor="filePicker"  style={{cursor:"pointer"}}>*/}
                                                {/*        <i className="fal fa-upload"></i>Incarca fisier*/}
                                                {/*        <input id="filePicker" type="file" name="file" onChange={uploadFile} style={{display: "none"}}></input>*/}
                                                {/*        <button type="submit" >*/}
                                                {/*            <i className="fal fa-upload" />*/}
                                                {/*        </button>*/}
                                                {/*    </label>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </form>
                                    </div>
                                )}

                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="thumb">
                                    {/*{!image && (*/}
                                    {/*    <img src={thumb} alt="" />*/}
                                    {/*)}*/}
                                    {/*{image && (*/}
                                    {/*    <img src={image} alt="" />*/}
                                    {/*)}*/}
                                    {image !== 'data:null;base64,null' && (
                                        <img src={image} alt="" />
                                    )}
                                    {image === 'data:null;base64,null' && (
                                        <img src={thumb} alt="" />
                                    )}
                                </div>
                                {isShown && (
                                    <div>
                                        <Quill
                                            actualText={{
                                                container: document.getElementById('tryEdit'),
                                            }}
                                        />
                                        <button type="submit" onClick={showEditor}>
                                            Save
                                        </button>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="appie-section-title text-center" style={{paddingTop: '50px'}}>
                                            {isTitleEditable && (
                                                <form onSubmit={updateText}>
                                                    <label htmlFor="filePicker1">
                                                        <input id="filePicker1" type="text" placeholder={title} name="file1" onChange={handleTitleChange}>
                                                        </input>
                                                        <button type="submit" >
                                                            Save
                                                        </button>
                                                    </label>
                                                </form>
                                            )}
                                            {!isTitleEditable && (
                                                <h3 className="appie-title" onClick={() => setIsTitleEditable(!isTitleEditable)}>{title}</h3>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div id="tryEdit" className="content text-justify" onClick={showEditor} dangerouslySetInnerHTML={{__html: text}}>
                                    {/*<p>*/}
                                    {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Curs;
