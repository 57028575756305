import React, {useEffect, useState} from 'react';
import Axios from "axios";
import FileDownload from "js-file-download";
import axiosInstance from "../Helper/AxiosHelper";
import BorderSpinner from "../../lib/BorderSpinner";

const SectiuniDocumente = () => {
    const [viewOnlyUsersWithBusinessPlan, setViewOnlyUsersWithBusinessPlan] = useState(true);
    const [users, setUsers] = useState([]);
    const [isShowingSpinner, setIsShowingSpinner] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        axiosInstance.get('/api/sv/user/all').then((response) => {
            setUsers(response.data);
            console.log(response.data)
            setIsShowingSpinner(false);
        })
    }, []);

    const downloadFile = (user) => (e) => {
        e.preventDefault();
        setIsDownloading(true);
        axiosInstance.get(`/api/sv/files/download/${user.id}`, {
            responseType:"blob"
        }).then((res) => {
            FileDownload(res.data, user.fileName, user.type)
            setIsDownloading(false)
        })
    }

    function onChangeInput(e) {
        setViewOnlyUsersWithBusinessPlan(e.target.checked);
    }

    return (
        <section className="appie-blog-3-area pt-90 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Documente incarcate</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <label className="form-check-label" htmlFor="defaultCheck2">Vezi doar candidatii ce au depus planul de afaceri
                            </label>
                            <div className="form-check mb-3">
                                <input className="form-check-input" name="final" type="checkbox" checked={viewOnlyUsersWithBusinessPlan} id="defaultCheck2" onChange={onChangeInput} />
                            </div>
                        </div>
                    </div>
                </div>
                {isShowingSpinner && (
                    <>
                        <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                            <BorderSpinner style={{ alignItems: 'center' }} />
                        </div>
                        <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                            <p>Se conecteaza la baza de date...</p>
                        </div>
                    </>
                )}
                {isDownloading && (
                    <>
                        <div className="row mt-4" style={{ display: 'flex', justifyContent: 'center' }}>
                            <BorderSpinner style={{ alignItems: 'center' }} />
                        </div>
                        <div className="row mb-4" style={{ display: 'flex', justifyContent: 'center' }}>
                            <p>Planul de afaceri este in curs de descarcare...</p>
                        </div>
                    </>
                )}
                <div className="row">
                    {users.map((user) => {
                        if (user.email !== "cristina.udateanu@goodstory.ro") {
                            if (viewOnlyUsersWithBusinessPlan ? user.isBusinessPlan : true) {
                                return (
                                    <div className="col-lg-6" key={user.id}>
                                        <div className="appie-blog-item-3">
                                            <div className="content">
                                                <div className="row">
                                                    <h3 className="appie-title">{user.lastName} {user.firstName}</h3>
                                                </div>
                                                <div className="row align-items-center ">
                                                    <ul>
                                                        <li>
                                                            {user.isBusinessPlan && (
                                                                <label onClick={downloadFile(user)} style={{cursor:"pointer"}}>
                                                                    <i className="fal fa-download"></i> Descarca planul de afaceri
                                                                </label>
                                                            )}
                                                            {!user.isBusinessPlan && (
                                                                <label>
                                                                    <i className="fal fa-times-hexagon"></i> Nu exista documente incarcate
                                                                </label>
                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectiuniDocumente;
