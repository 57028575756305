import React from 'react';

const domeniiDeFormare = [
    "Lucrator finisor in structuri pentru constructii",
    "Baby sitter",
    "Brutar",
    "Sudor electric",
    "Lucrator in structuri pentru constructii"
]

const FormareProfesionala = () => {
    return (
        <>
            <section
                className="appie-services-2-area appie-services-8-area pt-90 pb-55"
                id="service"
            >
                <div className="container" >
                    <div className="row align-items-end">
                        <div className="col-lg-12 col-md-8" >
                            <div className="appie-section-title" >
                                <h3 className="appie-title" align="center">Formare Profesionala</h3>
                                <br/>
                                <p align="justify" style={{fontSize: '24px'}}>
                                    <b>Ce sprijinim in cadrul proiectului?</b>
                                </p>
                                <p align="justify" style={{fontSize: '20px'}}>
                                    In cadrul proiectului vom sprijini participarea la programe de formare profesionala a unui numar de
                                    320 de tineri NEETs din grupul tinta al proiectului cu nivelurile de ocupabilitate B, C si D, respectiv
                                    „mediu ocupabil”, „greu ocupabil” si „foarte greu ocupabil”.
                                </p>
                                <br/>
                                <p align="justify" style={{fontSize: '24px'}}>
                                    <b>Domenii formare:</b>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="row" style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                {domeniiDeFormare.map((element) =>
                                    <div className="col-lg-2 col-md-6" align="center">
                                        <div
                                            className="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="400ms"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div className="icon">
                                                <i className="fal fa-user-hard-hat" />
                                            </div>
                                            <h4 className="title">{element}</h4>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="appie-section-title" >
                        <p align="justify" style={{fontSize: '20px'}}>
                            Cursurile sunt nivel 2 de calificare, 360 de ore.
                        </p>
                        <p align="justify" style={{fontSize: '20px'}}>
                            La finalul cursului, participantii primesc o subventie de 1700 lei si diploma de calificare recunoscuta
                            pe piata muncii.
                        </p>
                    </div>
                    <div className="appie-section-title" >
                    <p align="justify" style={{fontSize: '24px'}}>
                        <b>Cum se vor desfasura cursurile?</b>
                    </p>
                    <p align="justify" style={{fontSize: '20px'}}>
                        Cursurile vor fi organizate in 16-22 grupe constituite din 14-20 persoane fiecare, in functie de
                        cerintele membrilor GT si de angajatorii identificati in judetele din regiune, asigurandu-se pe cat
                        posibil proportionalitatea intre judete. Programele de formare profesionala vor fi cursuri de
                        calificare si vor avea o durata de 360 de ore/curs (nivel 2) in meseriile de zidar pietrar, tencuitor si
                        frizer,coafor, manichiurist si pedichiurist. Repartizarea acestor ore va viza atat o componenta
                        teoretica, cat si o componenta practica, in conformitate cu prevederile legale in vigoare. Se vor
                        intocmi liste de prezenta cu semnaturi ale participantilor pentru fiecare zi de formare.
                    </p>
                </div>
                </div>
            </section>
        </>
    );
};

export default FormareProfesionala;
